<template>
  <div>
    <div v-if="props.editable">
      <div class="flex flex-row justify-between items-center gap-16">
        <div class="text-l font-bold">
          {{ t('offers.photos') }}
        </div>
        <AgButton v-if="!stream" variant="ghost" class="px-8 text-link" @click="openStream" :disabled="!isMediaDevices">
          {{ t('offers.openCamera') }}
          <template #icon><IcAdd /></template>
        </AgButton>
        <AgButton v-if="stream" class="my-8 text-link" variant="ghost" @click.prevent="closeStream">
          {{ t('offers.closeCamera') }}
          <template #icon><IcClose /></template>
        </AgButton>
      </div>
      <div class="gap-4 text-s-13 flex items-center">
        <AgButton variant="ghost" class="cursor-default flex">
          <template #icon>
            <IcInfo class="text-infoBlue h-[20px] w-[20px]" />
          </template>
        </AgButton>
        <span class="italic font-light">{{ t('offers.imagesTaken') }}</span>
      </div>
    </div>

    <div class="py-8">
      <div class="flex flex-row justify-between max-md:flex-col gap-8 border-neutral-550">
        <div v-if="stream && props.editable" class="basis-1/2 border-r border-neutral-550">
          <video ref="video"><track kind="captions" /></video>
          <canvas ref="canvas" class="hidden"></canvas>
          <div class="py-8 flex">
            <AgButton
              class="px-8"
              variant="secondary"
              @click.prevent="takePhoto"
              :disabled="selectedPhoto === undefined && fileImages.length > 1"
            >
              {{ t('offers.takePhoto') }}
              <template #icon><IcAdd /></template>
            </AgButton>
          </div>
        </div>
        <div v-if="isLoading">
          <IcLoading />
        </div>
        <div v-else-if="!isLoading && fileImages.length > 0" class="gap-2" :class="{ flex: !props.editable }">
          <div :class="getPhotoOneClass" class="flex">
            <span v-if="fileImages[0] && fileImages[0].size > 0">
              <img
                :src="imgToDisplayableImage(0)"
                alt="Photo one"
                width="240"
                height="180"
                :class="{ 'border-danger border-4 ': selectedPhoto === 0, 'h-[140px] w-[180px]': !props.editable }"
              />
            </span>
            <span v-else>{{ t('offers.imageNotFound') }}</span>
            <div class="flex flex-col">
              <AgButton variant="ghost" class="text-danger" @click="removeImage(0)" v-if="props.editable">
                <IcDelete class="flex justify-start" />
              </AgButton>
              <AgButton variant="ghost" class="text-link" @click="setSelectedPhoto(0)" v-if="props.editable">
                <IcEdit />
              </AgButton>
              <AgButton variant="ghost" class="text-success" @click="downloadPhoto(0, true)">
                <IcDownload />
              </AgButton>
            </div>
          </div>
          <div v-if="fileImages.length > 1" :class="getPhotoTwoClass" class="flex flex-row">
            <span v-if="fileImages[1] && fileImages[1].size > 0">
              <img
                :src="imgToDisplayableImage(1)"
                alt="Photo two"
                width="240"
                height="180"
                :class="{ 'border-danger border-4 ': selectedPhoto === 1, 'h-[140px] w-[180px]': !props.editable }"
              />
            </span>
            <span v-else>{{ t('offers.imageNotFound') }}</span>
            <div class="flex flex-col">
              <AgButton variant="ghost" class="text-danger" @click="removeImage(1)" v-if="props.editable">
                <IcDelete />
              </AgButton>
              <AgButton variant="ghost" class="text-link" @click="setSelectedPhoto(1)" v-if="props.editable">
                <IcEdit />
              </AgButton>
              <AgButton variant="ghost" class="text-success" @click="downloadPhoto(1, true)">
                <IcDownload />
              </AgButton>
            </div>
          </div>
        </div>
        <div v-else-if="!isLoading && fileImages.length === 0 && (!stream || !track) && props.editable" class="w-full">
          <AgAlert variant="info" :dismissible="true">{{ t('offers.noPhotos') }}</AgAlert>
        </div>
      </div>
      <div class="w-full my-12" v-if="imgTooBig">
        <AgAlert variant="danger">{{ t('offers.photoTooBig') }}</AgAlert>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import IcAdd from '@/components/icons/IcAdd.vue';
  import IcClose from '@/components/icons/IcClose.vue';
  import IcDelete from '@/components/icons/IcDelete.vue';
  import IcDownload from '@/components/icons/IcDownload.vue';
  import IcEdit from '@/components/icons/IcEdit.vue';
  import IcInfo from '@/components/icons/IcInfo.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import { useCommon } from '@/composables/useCommon';
  import type { OfferDto } from '@/domain/offerData/OfferDto';
  import { i18n } from '@/plugins/i18n';
  import { computed, onBeforeMount, onBeforeUnmount, ref, watch } from 'vue';

  interface Props {
    offer: OfferDto;
    editable: boolean;
  }

  const props = defineProps<Props>();

  const { openFile, deletePhoto, savePhoto } = useCommon();
  const { t } = i18n.global;

  const fileImages = ref<Array<File>>([]);
  const isLoading = ref(false);
  const video = ref<HTMLVideoElement>();
  const canvas = ref<HTMLCanvasElement>();
  const isPhotoTaken = ref<boolean>(false);

  const stream = ref<MediaStream>();
  const track = ref<MediaStreamTrack>();
  const imgTooBig = ref<boolean>(false);
  const selectedPhoto = ref<number>();

  const isMediaDevices = computed(() => (navigator.mediaDevices ? true : false));

  const calculatedDeliveryAddress = computed(() => {
    if (props.offer.deliveryAddressEqualToBilling) {
      return props.offer.invoiceAddress;
    } else if (props.offer.deliveryAddress) {
      return props.offer.deliveryAddress;
    }
  });

  const getPhotoOneClass = computed(() => {
    if (fileImages.value.length > 0) {
      return 'visible';
    }
    return 'invisible';
  });

  const getPhotoTwoClass = computed(() => {
    let cls = '';
    if (fileImages.value.length > 1) {
      cls += 'visible';
    } else {
      cls += 'invisible';
    }
    if (props.editable) {
      cls += ' mt-8';
    }
    return cls;
  });

  async function takePhoto() {
    if (canvas.value && video.value && stream.value) {
      isPhotoTaken.value = true;

      const { videoWidth, videoHeight } = video.value;
      canvas.value.width = videoWidth;
      canvas.value.height = videoHeight;

      const context = canvas.value.getContext('2d');
      if (context) {
        context.drawImage(video.value, 0, 0, videoWidth, videoHeight);

        canvas.value.toBlob(async (blob) => {
          if (blob) {
            const index = fileImages.value.length % 2;

            const gid = props.offer.gid ?? `${new Date().toISOString().split('T')[0]}`;
            const date = `${new Date().getFullYear()}`;
            const timestamp = Date.now();

            const fileName = `${gid}_${date}_${timestamp}.png`;
            const file = new File([blob], fileName);
            if (file.size > 500000) {
              imgTooBig.value = true;
              setTimeout(() => {
                imgTooBig.value = false;
              }, 3000);
            } else if (selectedPhoto.value === undefined) {
              fileImages.value[index] = file;
              await savePhoto(file);
              if (calculatedDeliveryAddress.value && calculatedDeliveryAddress.value.photos) {
                calculatedDeliveryAddress.value.photos[index] = fileName;
              }
            } else if (selectedPhoto.value !== undefined) {
              fileImages.value[selectedPhoto.value] = file;
              if (calculatedDeliveryAddress.value && calculatedDeliveryAddress.value.photos) {
                calculatedDeliveryAddress.value.photos[selectedPhoto.value] = fileName;
              }
              await savePhoto(file);
              selectedPhoto.value = undefined;
            }
          }
        });
      }
    }
  }

  const loadPhotos = async function () {
    calculatedDeliveryAddress.value?.photos.forEach(async (name, index) => {
      const blob = await downloadPhoto(index, false);
      if (blob) {
        fileImages.value[index] = new File([blob], name);
      } else {
        fileImages.value[index] = new File([], name);
      }
    });
    isLoading.value = false;
  };

  const downloadPhoto = async function (index: number, open: boolean) {
    if (
      calculatedDeliveryAddress.value &&
      calculatedDeliveryAddress.value.photos.length > 0 &&
      index < calculatedDeliveryAddress.value.photos.length
    ) {
      const fileName = calculatedDeliveryAddress.value.photos[index];
      return openFile(fileName, open);
    }
  };

  const imgToDisplayableImage = function (index: number) {
    try {
      if (fileImages.value && fileImages.value.length > 0 && index < fileImages.value.length) {
        const file = fileImages.value[index];
        if (file && file.size > 0) {
          const url = window.URL.createObjectURL(file);
          return url;
        }
      }
    } catch (e) {}
    return '';
  };

  const removeImage = async function (index: number) {
    if (
      calculatedDeliveryAddress.value &&
      calculatedDeliveryAddress.value.photos &&
      calculatedDeliveryAddress.value.photos.length > 0 &&
      index < calculatedDeliveryAddress.value.photos.length
    ) {
      const fileName = calculatedDeliveryAddress.value.photos[index];
      if (fileImages.value[index] && fileImages.value[index].size > 0) {
        deletePhoto(fileName);
      }
      calculatedDeliveryAddress.value.photos.splice(index, 1);
      fileImages.value.splice(index, 1);
    }
  };

  function openStream() {
    const constraints = {
      audio: false,
      video: {
        facingMode: 'environment',
      },
    };
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((mediaStream) => {
        stream.value = mediaStream;
        track.value = mediaStream.getTracks()[0];

        isPhotoTaken.value = false;
      })
      .catch((error) => console.log('Error: ', error));
    isLoading.value = false;
  }

  function closeStream() {
    if (stream.value && track.value) {
      track.value.stop();
    }
    stream.value = undefined;
    track.value = undefined;
    canvas.value = undefined;
    video.value = undefined;
    selectedPhoto.value = undefined;
  }

  const setSelectedPhoto = (index: number) => {
    selectedPhoto.value = index;
    if (!stream.value && !track.value) {
      openStream();
    }
  };

  watch(video, async (element) => {
    if (element instanceof HTMLVideoElement) {
      video.value = element;
      video.value.srcObject = stream.value ?? null;
      await video.value.play();
    }
  });

  onBeforeMount(async () => {
    if (calculatedDeliveryAddress.value && calculatedDeliveryAddress.value.photos.length > 0) {
      isLoading.value = true;
      await loadPhotos();
    }
  });

  onBeforeUnmount(() => {
    if (stream.value && track.value) {
      track.value.stop();
    }
  });
</script>
