import type { DimensionItemDto } from '@/domain/DimensionItemDto';
import type { TradeAgreementPriceAndDiscounts } from '@/domain/internal/TradeAgreementPriceAndDiscounts';
import type { AddressDto } from '@/domain/masterData/AddressDto';
import type { CustomerDto } from '@/domain/masterData/CustomerDto';
import type { DiscountDto } from '@/domain/masterData/DiscountDto';
import type { TradeAgreementDto } from '@/domain/masterData/TradeAgreementDto';
import type { OfferAddressDto } from '@/domain/offerData/OfferAddressDto';
import type { OfferBusinessCategoryDto } from '@/domain/offerData/OfferBusinessCategoryDto';
import type { OfferCustomerDto } from '@/domain/offerData/OfferCustomerDto';
import type { OfferDto } from '@/domain/offerData/OfferDto';

import { useApi } from '@/composables/api/useApi';
import { useMasterData } from '@/composables/data/useMasterData';
import { useUserData } from '@/composables/data/useUserData';
import { useCommon } from '@/composables/useCommon';
import { useNordwal } from '@/composables/useNordwal';
import { config } from '@/config/config';
import type { ContainerDto } from '@/domain/data/ContainerDto';
import type { DownloadDto } from '@/domain/DownloadDto';
import { CustomerAddressType } from '@/domain/enumeration/CustomerAddressType';
import { CustomerType } from '@/domain/enumeration/CustomerType';
import { DiscountType } from '@/domain/enumeration/DiscountType';
import { OfferStatus } from '@/domain/enumeration/OfferStatus';
import { SegmentType } from '@/domain/enumeration/SegmentType';
import { i18n } from '@/plugins/i18n';
import type { DownloadArticleFilters } from '@/util/filters/DownloadArticleFilters';
import { Optional } from '@/util/Optional';
import { useError } from '@/util/useError';
import { UUID } from '@/util/UUID';
import { cloneDeep } from 'lodash';

export function useOfferFactory() {
  const userData = useUserData();
  const masterData = useMasterData();
  const common = useCommon();

  const { handleCatchLocalError, handleCatchServerError } = useError();
  const api = useApi();

  const { t, locale } = i18n.global;

  function currentTimestamp() {
    return Math.floor(Date.now() / 1000);
  }

  const copyOffer = function (offer: OfferDto) {
    const timestamp = currentTimestamp();

    const newOffer: OfferDto = cloneDeep(offer);
    newOffer.gid = UUID().v4();
    newOffer.createdAt = timestamp;
    newOffer.modifiedAt = timestamp;

    return newOffer;
  };

  const createOfferForCustomer = async function (customerCode: string): Promise<OfferDto> {
    const customer = await getCustomer(customerCode);
    if (!customer) {
      throw new Error(t('offers.customerNotFound'));
    }
    const offerCustomer: OfferCustomerDto = await toOfferCustomer(customer);
    const offer: OfferDto = await createOfferWithCustomer(offerCustomer, customer.addresses);
    const discount: Optional<DiscountDto> = await resolveHeadDiscount(customer.id);
    offer.discount = 0;
    if (discount.isPresent() && discount.get().discount1) {
      offer.discount = discount.get().discount1 || 0;
    }
    offer.agentId = customer.agentId;
    if (customer.agentName) {
      offer.agentName = customer.agentName;
    }
    return offer;
  };

  const createOfferForOfferCustomer = async function (offerCustomer: OfferCustomerDto): Promise<OfferDto> {
    const offer: OfferDto = await createNewOffer(offerCustomer);
    offer.invoiceAddress = createNewEmptyInvoiceAddress();
    offer.deliveryAddress = null;

    return offer;
  };

  const toOfferCustomer = async function (customer: CustomerDto): Promise<OfferCustomerDto> {
    const offerCustomer: OfferCustomerDto = {
      // CUSTOMER INFO
      id: customer.id,

      priceListId: customer.priceListId,
      discountListId: customer.discountListId,
      customerTaxCode: customer.customerTaxCode,
      code: customer.code,
      name: customer.name,
      vatCode: customer.vatCode,
      fiscalCode: customer.fiscalCode,
      sdiCode: customer.sdiCode,
      iban: customer.iban,
      language: customer.language?.toLowerCase(),
      note: customer.note,
      invoiceCode: customer.invoiceCode,
      alternativeName: customer.alternativeName,
      webSiteUrl: customer.webSiteUrl,
      segment: customer.segment,
    };
    // Remove whitespaces from customer's iban
    if (offerCustomer.iban) {
      offerCustomer.iban = offerCustomer.iban.replace(/\s+/g, '');
    }
    // Remove whitespaces from fiscal code
    if (offerCustomer.fiscalCode) {
      offerCustomer.fiscalCode = offerCustomer.fiscalCode.replace(/\s+/g, '');
    }
    return offerCustomer;
  };

  const resolveBusinessCategory = async function (
    businessCategoryId: number | null
  ): Promise<OfferBusinessCategoryDto | null> {
    if (!businessCategoryId) {
      return null;
    }
    const businessCategory = await masterData.tables.businessCategories.get(businessCategoryId);
    if (!businessCategory) {
      throw new Error('offers.CATEGORIES_ERROR' + businessCategoryId);
    }
    return {
      id: businessCategory.id,
      code: businessCategory.code,
      title: businessCategory.title,
    };
  };

  const resolveHeadDiscount = async function (customerId: number): Promise<Optional<DiscountDto>> {
    const headDiscounts = await masterData.tables.discounts.where('type').equals(DiscountType.HEAD).toArray();

    const foundDiscounts = headDiscounts.filter((discount) => {
      return discount.customerType === CustomerType.CUSTOMER && discount.customerId === customerId;
    });
    if (foundDiscounts.length > 0) {
      return Optional.of(foundDiscounts[0]);
    }
    return Optional.empty();
  };

  const createOfferWithCustomer = async function (
    offerCustomer: OfferCustomerDto,
    addresses: Array<AddressDto>
  ): Promise<OfferDto> {
    const billingAddress = addresses.find((a) => a.type != CustomerAddressType.DELIVERY);
    if (!billingAddress) {
      // Sorry, customer has no billing address!
      return Promise.reject(t('offers.noBillingAddress'));
    }
    const offer: OfferDto = await createNewOffer(offerCustomer);
    const newInvoiceAddress: OfferAddressDto = {
      id: billingAddress.id,
      code: billingAddress.code,
      name: billingAddress.name,
      type: billingAddress.type,
      street: billingAddress.street,
      city: billingAddress.city,
      zip: billingAddress.zip,
      region: billingAddress.region,
      nation: billingAddress.nation,
      district: billingAddress.district,
      pec: billingAddress.pec,
      email: billingAddress.email,
      daysOfRest: billingAddress.daysOfRest,
      unloadingTimes: billingAddress.unloadingTimes,
      deliveryPhoneNotification: billingAddress.deliveryPhoneNotification,
      phoneNumber: billingAddress.phoneNumber,
      latitude: '',
      longitude: '',
      photos: [],
      deliveryPhoneNumber: billingAddress.deliveryPhoneNumber,
      businessCategory: await resolveBusinessCategory(billingAddress.businessCategoryId),
    };
    offer.invoiceAddress = newInvoiceAddress;
    // SET TO DELIVERY TO DEFAULT VALUE NULL
    offer.deliveryAddress = null;
    if (CustomerAddressType.BOTH === billingAddress.type) {
      offer.deliveryAddress = cloneDeep(offer.invoiceAddress);
    } else {
      const deliveryAddresses = addresses.filter((a) => a.type != CustomerAddressType.BILLING);
      if (deliveryAddresses.length === 1) {
        const newDeliveryAddress: OfferAddressDto = {
          id: deliveryAddresses[0].id,
          code: deliveryAddresses[0].code,
          name: deliveryAddresses[0].name,
          type: deliveryAddresses[0].type,
          street: deliveryAddresses[0].street,
          city: deliveryAddresses[0].city,
          zip: deliveryAddresses[0].zip,
          region: deliveryAddresses[0].region,
          nation: deliveryAddresses[0].nation,
          district: deliveryAddresses[0].district,
          pec: deliveryAddresses[0].pec,
          email: deliveryAddresses[0].email,
          daysOfRest: deliveryAddresses[0].daysOfRest,
          unloadingTimes: deliveryAddresses[0].unloadingTimes,
          deliveryPhoneNotification: deliveryAddresses[0].deliveryPhoneNotification,
          phoneNumber: deliveryAddresses[0].phoneNumber,
          latitude: '',
          longitude: '',
          photos: [],
          deliveryPhoneNumber: deliveryAddresses[0].deliveryPhoneNumber,
          businessCategory: await resolveBusinessCategory(deliveryAddresses[0].businessCategoryId),
        };
        offer.deliveryAddress = newDeliveryAddress;
      }
    }
    return offer;
  };

  const createNewOffer = async function (customer: OfferCustomerDto): Promise<OfferDto> {
    const hierarchy = await getAllHierarchy();

    const timestamp = currentTimestamp();

    const offer: OfferDto = {
      gid: UUID().v4(),
      textEmailBody: '',
      createdAt: timestamp,
      modifiedAt: timestamp,
      offerVersion: 0,
      status: OfferStatus.DRAFT,
      offerDate: null,
      offerNumber: '',
      agentHierarchyList: hierarchy,
      meta: {
        validationActive: {
          customer: false,
          shoppingCart: false,
          offerData: false,
          notes: false,
        },
      },
      synced: 0,
      customer: customer,
      invoiceAddress: createNewEmptyInvoiceAddress(),
      deliveryAddress: null,
      paymentMethod: await getDefaultPaymentMethod(),
      paymentTerm: await getDefaultPaymentTerm(),
      deliveryMethod: await getDefaultDeliveryMethod(),
      deliveryTerm: await getDefaultDeliveryTerm(),
      deliveryDateRequest: null,
      discount: 0,
      invoiceAmount: null,
      note: customer.note ?? '',
      items: [],
      orderReferences: [],
      deliveryAddressEqualToBilling: false,
      userId: 0,
    };
    if (hierarchy[0]) {
      offer.agentId = hierarchy[0].authUser.id;
      offer.agentName = hierarchy[0].authUser.name;

      if (hierarchy[0].workerAuthUser) {
        offer.userId = hierarchy[0].workerAuthUser.id;
        offer.userName = hierarchy[0].workerAuthUser.name;
      } else {
        offer.userId = hierarchy[0].authUser.id;
        offer.userName = hierarchy[0].authUser.name;
      }
    }
    return offer;
  };

  const createNewEmptyOfferCustomer = function (): OfferCustomerDto {
    const newEmptyOfferCustomer: OfferCustomerDto = {
      id: null,
      customerTaxCode: null,
      segment: SegmentType.SALES,
      code: '',
      name: '',
      language: '',
      note: '',
      vatCode: '',
      fiscalCode: '',
      sdiCode: '',
      iban: '',
      priceListId: -1,
      discountListId: null,
      alternativeName: '',
      invoiceCode: '',
      webSiteUrl: '',
      isPrivate: false,
    };
    return newEmptyOfferCustomer;
  };

  const createNewEmptyInvoiceAddress = function (): OfferAddressDto {
    const newEmptyInvoiceAddress: OfferAddressDto = {
      id: null,
      code: '',
      name: '',
      district: '',
      zip: '',
      type: CustomerAddressType.BILLING,
      street: '',
      phoneNumber: '',
      deliveryPhoneNumber: null,
      email: '',
      pec: '',
      city: '',
      nation: '',
      region: '',
      latitude: null,
      longitude: null,
      deliveryPhoneNotification: false,
      unloadingTimes: [],
      daysOfRest: [],
      photos: [],
      businessCategory: null,
    };
    return newEmptyInvoiceAddress;
  };

  const createNewEmptyDeliveryAddress = function (): OfferAddressDto {
    const newEmptyDeliveryAddress: OfferAddressDto = {
      id: null,
      code: '',
      name: '',
      district: '',
      zip: '',
      type: CustomerAddressType.DELIVERY,
      street: '',
      phoneNumber: '',
      deliveryPhoneNumber: null,
      email: '',
      pec: '',
      city: '',
      nation: '',
      region: '',
      latitude: null,
      longitude: null,
      deliveryPhoneNotification: false,
      unloadingTimes: [],
      daysOfRest: [],
      photos: [],
      businessCategory: null,
    };
    return newEmptyDeliveryAddress;
  };

  const getAllHierarchy = async function () {
    return (await userData.tables.hierarchy.toArray()) || [];
  };

  const getDefaultPaymentMethod = async function () {
    return (await masterData.tables.paymentMethods.filter((item) => item.default === true).first()) || null;
  };

  const getDefaultPaymentTerm = async function () {
    return (await masterData.tables.paymentTerms.filter((item) => item.default === true).first()) || null;
  };

  const getDefaultDeliveryMethod = async function () {
    return (await masterData.tables.deliveryMethods.filter((item) => item.default === true).first()) || null;
  };

  const getDefaultDeliveryTerm = async function () {
    return (await masterData.tables.deliveryTerms.filter((item) => item.default === true).first()) || null;
  };

  const getDimOptionsByArticle = async function (offer: OfferDto, articleId: number, priceGroupIds: Array<number>) {
    const article = await masterData.tables.articles.get(articleId);
    if (article) {
      const customerId = offer.customer.id;
      const priceListId = offer.customer.priceListId;

      const allTradeAgreement = await common.getTradeAgreements(articleId, priceGroupIds, customerId, priceListId);
      const taDimensionIds = allTradeAgreement
        .filter((t) => !!t.articleDimensionValueConfigId)
        .map((t) => t.articleDimensionValueConfigId);

      let dimensionIds: number[] = article.articleDimensionValueConfigIds;
      if (dimensionIds.length === 0) {
        return null;
      }
      if (taDimensionIds.length > 0) {
        dimensionIds = dimensionIds.filter((d) => taDimensionIds.includes(d));
      }
      return await getDimOptions(dimensionIds, article.defaultDimensionValueConfigIds);
    }
    return null;
  };

  const getDimOptions = async function (dimensionIds: Array<number>, defaultDimensions: Array<number> = []) {
    if (dimensionIds.length === 0) {
      return null;
    }
    const dimensionValueConfigs = await masterData.tables.articleDimensionValueConfigs.toArray();
    const dimOptions: { [key: string]: Array<DimensionItemDto> } = {};

    dimensionValueConfigs.sort((a, b) => {
      const vb = b.title[locale.value];
      const va = a.title[locale.value];

      if (!va && !vb) {
        return 0;
      } else if (!va) {
        return 1;
      } else if (!vb) {
        return -1;
      }
      return va.localeCompare(vb);
    });

    dimensionValueConfigs.forEach((item) => {
      if (dimensionIds.includes(item.id)) {
        const key = item.dimensionConfig.id.toString();
        if (dimOptions[key]) {
          dimOptions[key].push({
            id: item.dimensionConfig.id,
            code: item.dimensionConfig.code,
            title: item.dimensionConfig.title,
            value: {
              id: item.id,
              code: item.code,
              title: item.title,
              default: defaultDimensions.includes(item.id),
            },
          });
        } else {
          dimOptions[key] = [
            {
              id: item.dimensionConfig.id,
              code: item.dimensionConfig.code,
              title: item.dimensionConfig.title,
              value: {
                id: item.id,
                code: item.code,
                title: item.title,
                default: defaultDimensions.includes(item.id),
              },
            },
          ];
        }
      }
    });
    return dimOptions;
  };

  const getTradeAgreementPriceAndDisconts = async function (
    offer: OfferDto,
    articleId: number,
    priceGroupIds: Array<number>,
    qt: number = 0,
    dimValConfId?: number
  ) {
    const tradeAgreementPriceAndDiscounts: TradeAgreementPriceAndDiscounts = {
      tradeAgreement: undefined,
      price: 0,
      discounts: [],
    };

    if (offer) {
      const customerId = offer.customer.id;
      const priceListId = offer.customer.priceListId;
      const discountListId = offer.customer.discountListId;
      const segment = offer.customer.segment;

      const allTradeAgreement = await common.getTradeAgreements(articleId, priceGroupIds, customerId, priceListId);
      const tradeAgreements = [];
      if (dimValConfId) {
        const tempTa = allTradeAgreement
          .filter((t) => t.fromQuantity <= qt && t.articleDimensionValueConfigId === dimValConfId)
          .sort((t1, t2) => t1.fromQuantity - t2.fromQuantity);

        tradeAgreements.push(...tempTa);
      } else {
        const tempTa = allTradeAgreement
          .filter((t) => t.fromQuantity <= qt && !t.articleDimensionValueConfigId)
          .sort((t1, t2) => t1.fromQuantity - t2.fromQuantity);

        tradeAgreements.push(...tempTa);
      }
      /* GET TRADE AGREEMENT */
      const tradeAgreement = getFinalTradeAgreement(tradeAgreements, segment);

      /* GET DISCOUNT */
      const isPromotional =
        tradeAgreement && tradeAgreement.price > 0 && tradeAgreement.customerType === CustomerType.PROMOTION;

      let discount: DiscountDto | undefined = undefined;
      if (!isPromotional && tradeAgreement) {
        if (config.company.code === 'NORDWAL') {
          const nordwal = useNordwal();

          const priceGroupId = tradeAgreement.priceGroupId ?? null;

          const discounts = await nordwal.getPositionDiscounts(
            tradeAgreement.customerType,
            articleId,
            priceGroupId,
            customerId,
            priceListId,
            qt
          );
          if (discounts.length > 0 && tradeAgreement.customerType !== CustomerType.ALL && tradeAgreement.price === 0) {
            const baseTa = await nordwal.getBaseTradeAgreements(articleId, segment);
            if (baseTa) {
              tradeAgreement.price = baseTa.salesPrice;
              if (segment && segment === SegmentType.INDUSTRIAL) {
                tradeAgreement.price = baseTa.industrialPrice;
              }
            }
          }
          if (discounts.length > 0) {
            discount = nordwal.getFinalDiscount(tradeAgreement, discounts, segment, qt);
          }
        } else {
          const priceGroupId = tradeAgreement.priceGroupId ?? null;

          const discounts = await common.getPositionDiscounts(
            articleId,
            priceGroupId,
            customerId,
            priceListId,
            discountListId,
            qt
          );
          if (discounts.length > 0) {
            discount = getFinalDiscount(tradeAgreement, discounts, qt);
          }
        }
        if (discount) {
          /* NON ZERO DISCOUNT */
          type objectKey = keyof typeof discount;
          for (let i = 0; i < config.company.maxItemDiscounts; i++) {
            const key = `discount${i + 1}` as objectKey;
            const value = discount[key] as number;
            if (value && value > 0) {
              tradeAgreementPriceAndDiscounts.discounts.push(value);
            }
          }
        }
      }
      tradeAgreementPriceAndDiscounts.tradeAgreement = tradeAgreement;
      tradeAgreementPriceAndDiscounts.price = getPrice(segment, tradeAgreement);
    }
    return tradeAgreementPriceAndDiscounts;
  };

  const filterBySegment = function (ta: TradeAgreementDto, segment: string | null) {
    if (ta.salesPrice === 0 && ta.industrialPrice === 0) {
      return true;
    }
    if (segment && segment === SegmentType.INDUSTRIAL) {
      return ta.industrialPrice > 0;
    }
    return ta.salesPrice > 0;
  };

  const getFinalTradeAgreement = function (tas: TradeAgreementDto[], segment: string | null) {
    if (tas.length === 1) {
      return tas.pop();
    }
    const groupByQuantity = tas.reduce((group: { [key: number]: TradeAgreementDto[] }, ta: TradeAgreementDto) => {
      const fromQuantity = ta.fromQuantity;

      group[fromQuantity] = group[fromQuantity] ?? [];
      group[fromQuantity].push(ta);

      return group;
    }, {});
    const finaltas: TradeAgreementDto[] = [];

    Object.values(groupByQuantity).forEach((value) => {
      if (value.length > 1) {
        const taPromotion = value.filter((ta) => ta.customerType === CustomerType.PROMOTION);
        const taCustomerArticle = value.filter((ta) => ta.customerType === CustomerType.CUSTOMER_ARTICLE);
        const taCustomerPriceGroup = value.filter((ta) => ta.customerType === CustomerType.CUSTOMER_PRICEGROUP);
        const taPriceListArticle = value.filter((ta) => ta.customerType === CustomerType.PRICELIST_ARTICLE);
        const taPriceListPriceGroup = value.filter((ta) => ta.customerType === CustomerType.PRICELIST_PRICEGROUP);
        const taAll = value.filter((ta) => ta.customerType === CustomerType.ALL && filterBySegment(ta, segment));

        if (taPromotion.length > 0) {
          finaltas.push(taPromotion[0]);
        } else if (taCustomerArticle.length > 0) {
          finaltas.push(taCustomerArticle[0]);
        } else if (taCustomerPriceGroup.length > 0) {
          finaltas.push(taCustomerPriceGroup[0]);
        } else if (taPriceListArticle.length > 0) {
          finaltas.push(taPriceListArticle[0]);
        } else if (taPriceListPriceGroup.length > 0) {
          finaltas.push(taPriceListPriceGroup[0]);
        } else if (taAll.length > 0) {
          finaltas.push(taAll[0]);
        }
      } else {
        finaltas.push(...value);
      }
    });
    return finaltas.pop();
  };

  const getPrice = (segment: string | null, ta?: TradeAgreementDto) => {
    if (!ta) {
      console.warn('Trade agreement is not defined');
      return 0;
    }
    if (ta.customerType === CustomerType.ALL) {
      if (ta.salesPrice === 0 && ta.industrialPrice === 0) {
        return ta.price;
      }
      if (segment && segment.length > 0 && segment === SegmentType.INDUSTRIAL) {
        return ta.industrialPrice;
      }
      return ta.salesPrice;
    }
    return ta.price;
  };

  const getFinalDiscount = function (ta: TradeAgreementDto, ds: DiscountDto[], qt: number) {
    if (ds.length === 1) {
      return ds[0];
    }
    let finalDiscount: DiscountDto | undefined = undefined;

    const disWithoutDim = ds
      .filter((d) => !d.articleDimensionValueConfigId)
      .sort((d1, d2) => d1.fromQuantity - d2.fromQuantity);

    let discounts = disWithoutDim;
    if (ta.articleDimensionValueConfigId) {
      const disWithDim = ds
        .filter((d) => d.articleDimensionValueConfigId === ta.articleDimensionValueConfigId)
        .sort((d1, d2) => d1.fromQuantity - d2.fromQuantity);

      if (disWithDim.length > 0) {
        discounts = disWithDim;
      }
      if (discounts.length > 0 && discounts[0].fromQuantity > ta.fromQuantity) {
        const tfds = disWithoutDim.filter((d) => d.fromQuantity > 0 && d.fromQuantity < discounts[0].fromQuantity);
        if (tfds.length > 0) {
          const fdsg = tfds.filter((d) => d.fromQuantity > ta.fromQuantity);
          const fdsm = tfds.filter((d) => d.fromQuantity <= ta.fromQuantity);
          fdsg.unshift(fdsm[fdsm.length - 1]);

          discounts.unshift(...fdsg);
        }
      }
    }
    const discountsFromQt = discounts.filter((d) => d.fromQuantity === qt);
    if (discountsFromQt.length > 0) {
      finalDiscount = discountsFromQt[0];
    } else {
      discountsFromQt.push(
        discounts.reduce((d1, d2) => {
          return Math.abs(d2.fromQuantity - qt) < Math.abs(d1.fromQuantity - qt) ? d2 : d1;
        })
      );
      finalDiscount = discountsFromQt.length > 0 ? discountsFromQt[0] : undefined;
    }
    return finalDiscount;
  };

  const copyAddress = function (addressIncoming: AddressDto) {
    const outgoingAddress: AddressDto = {
      id: addressIncoming.id,
      businessCategoryId: addressIncoming.businessCategoryId,
      deliveryMethodId: addressIncoming.deliveryMethodId,
      deliveryTermId: addressIncoming.deliveryTermId,
      code: addressIncoming.code,
      name: addressIncoming.name,
      type: addressIncoming.type,
      street: addressIncoming.street,
      zip: addressIncoming.zip,
      city: addressIncoming.city,
      district: addressIncoming.district,
      region: addressIncoming.region,
      nation: addressIncoming.nation,
      phoneNumber: addressIncoming.phoneNumber,
      deliveryPhoneNumber: addressIncoming.deliveryPhoneNumber,
      email: addressIncoming.email,
      pec: addressIncoming.pec,
      deliveryPhoneNotification: addressIncoming.deliveryPhoneNotification,
      daysOfRest: addressIncoming.daysOfRest,
      unloadingTimes: addressIncoming.unloadingTimes,
    };
    return outgoingAddress;
  };

  const copyToOfferAddress = async function (addressIncoming: AddressDto) {
    const outgoingAddress: OfferAddressDto = {
      id: addressIncoming.id,
      businessCategoryId: addressIncoming.businessCategoryId,
      deliveryMethodId: addressIncoming.deliveryMethodId,
      deliveryTermId: addressIncoming.deliveryTermId,
      code: addressIncoming.code,
      name: addressIncoming.name,
      type: addressIncoming.type,
      street: addressIncoming.street,
      zip: addressIncoming.zip,
      city: addressIncoming.city,
      district: addressIncoming.district,
      region: addressIncoming.region,
      nation: addressIncoming.nation,
      phoneNumber: addressIncoming.phoneNumber,
      email: addressIncoming.email,
      pec: addressIncoming.pec,
      latitude: '',
      longitude: '',
      deliveryPhoneNotification: addressIncoming.deliveryPhoneNotification,
      daysOfRest: addressIncoming.daysOfRest,
      unloadingTimes: addressIncoming.unloadingTimes,
      photos: [],
      deliveryPhoneNumber: addressIncoming.deliveryPhoneNumber,
      businessCategory: await resolveBusinessCategory(addressIncoming.businessCategoryId),
    };
    return outgoingAddress;
  };

  const copyOfferAddress = function (addressIn: OfferAddressDto) {
    const addressOut: OfferAddressDto = {
      id: addressIn.id,
      businessCategoryId: addressIn.businessCategoryId,
      deliveryMethodId: addressIn.deliveryMethodId,
      deliveryTermId: addressIn.deliveryTermId,
      code: addressIn.code,
      name: addressIn.name,
      type: addressIn.type,
      street: addressIn.street,
      zip: addressIn.zip,
      city: addressIn.city,
      district: addressIn.district,
      region: addressIn.region,
      nation: addressIn.nation,
      phoneNumber: addressIn.phoneNumber,
      deliveryPhoneNumber: addressIn.deliveryPhoneNumber,
      email: addressIn.email,
      pec: addressIn.pec,
      latitude: addressIn.latitude,
      longitude: addressIn.longitude,
      photos: addressIn.photos,
      businessCategory: addressIn.businessCategory,
      deliveryPhoneNotification: addressIn.deliveryPhoneNotification,
      daysOfRest: addressIn.daysOfRest,
      unloadingTimes: addressIn.unloadingTimes,
    };
    return addressOut;
  };

  const initializeAddress = function (): OfferAddressDto {
    const initialazedAddress: OfferAddressDto = {
      id: null,
      businessCategoryId: null,
      deliveryMethodId: null,
      deliveryTermId: null,
      businessCategory: null,

      code: '',
      name: '',
      type: '',
      street: '',
      zip: '',
      city: '',
      district: '',
      region: '',
      nation: '',
      phoneNumber: '',
      deliveryPhoneNumber: '',
      email: '',
      pec: '',
      latitude: '',
      longitude: '',

      deliveryPhoneNotification: false,

      photos: [],
      daysOfRest: [],
      unloadingTimes: [],
    };
    return initialazedAddress;
  };

  const getCustomer = async (customerCode: string | undefined) => {
    if (customerCode) {
      const customer = await masterData.tables.customers.where('code').equals(customerCode).first();
      return customer;
    } else {
      return;
    }
  };

  const getArticleCustomerTax = async (customerTaxCode: string | null | undefined, articleId: number) => {
    if (!customerTaxCode) {
      return undefined;
    }
    const article = await masterData.tables.articles.where('id').equals(articleId).first();
    if (article && article.articleTaxCode) {
      const tax = await masterData.tables.taxes
        .where('[customerTaxCode+articleTaxCode]')
        .equals([customerTaxCode, article.articleTaxCode])
        .first();
      if (tax) {
        return tax.value;
      }
    }
    return undefined;
  };

  const getArticleDownloads = async function (filters: DownloadArticleFilters) {
    try {
      let articleDownloads: Array<DownloadDto> = [];
      const params = {
        'filters.language': filters.language,
        'filters.articleId': `${filters.articleId}`,
        ...(filters.articleGroupId ? { 'filters.articleGroupId': `${filters.articleGroupId}` } : ''),
      };
      const searchParams = new URLSearchParams(params);
      const requestUri = `/downloads/article?${searchParams}`;
      const container = await api.fetch<ContainerDto>(requestUri);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return [];
      }
      if (container.data.downloads) {
        articleDownloads = container.data.downloads;
      }
      return articleDownloads;
    } catch (e) {
      handleCatchLocalError(e);
      return [];
    }
  };

  async function openFile(url: string, download = false) {
    try {
      const blob = await api.download(url);
      const fileName = url.substring(url.lastIndexOf('/') + 1);
      const downloadUrl = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = downloadUrl;
      if (download) {
        link.download = fileName;
      }
      link.target = '_blank';
      link.click();

      setTimeout(function () {
        window.URL.revokeObjectURL(downloadUrl);
      }, 333);
    } catch (error) {
      handleCatchLocalError(error);
    }
  }

  return {
    initializeAddress,
    toOfferCustomer,
    createOfferForCustomer,
    createOfferForOfferCustomer,
    createNewEmptyOfferCustomer,
    createNewEmptyDeliveryAddress,
    getDimOptionsByArticle,
    getDimOptions,
    getTradeAgreementPriceAndDisconts,
    filterBySegment,
    getPrice,
    getFinalTradeAgreement,
    getFinalDiscount,
    getCustomer,
    copyOffer,
    copyAddress,
    copyToOfferAddress,
    copyOfferAddress,
    getArticleCustomerTax,
    getArticleDownloads,
    openFile,
  };
}
