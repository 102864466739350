<template>
  <div class="text-white mt-12 mb-32 md:mx-[80px] max-md:mx-16">
    <h1 class="text-3xl font-bold">{{ t('welcomeBackDashboard') }} {{ userName }}</h1>

    <div class="flex flex-col justify-between h-[calc(100vh-220px)] mt-24">
      <div class="m-auto">
        <component v-if="logo" :is="logo" />
      </div>

      <div v-if="config.company.modules.STATISTICS">
        <DashboardWidgetStatistics :infoStatistics="statisticsInfo" />
      </div>

      <div class="flex max-lg:flex-col gap-24 pt-24">
        <div v-if="config.company.modules.ORDER" class="pt-0 flex-grow">
          <DashboardWidgetOrders />
        </div>
        <div v-if="config.company.modules.OFFER" class="pt-0 flex-grow">
          <DashboardWidgetOffers />
        </div>
        <div v-if="config.company.modules.CONTINGENTS" class="pt-0 flex-grow">
          <DashboardWidgetContinget />
        </div>
        <div v-if="config.company.modules.OPEN_ITEMS" class="pt-0 flex-grow">
          <DashboardWidgetOpenItems :debtInfo="outstandingDebtInfo" class="max-lg:mt-0" />
        </div>
        <div v-if="config.company.modules.CHECKLISTS" class="pt-0 flex-grow">
          <DashboardWidgetChecklists :checklistsInfo="checklistsInfo" class="max-lg:mt-0" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import LogoCart from '@/components/logo/LogoCart.vue';
  import DashboardWidgetOffers from '@/modules/offers/components/dashbord-widget/DashboardWidgetOffers.vue';
  import DashboardWidgetOpenItems from '@/modules/open-items/components/dashboard-widget/DashboardWidgetOpenItems.vue';
  import DashboardWidgetOrders from '@/modules/orders/components/dashboard-widget/DashboardWidgetOrders.vue';
  import DashboardWidgetStatistics from '@/modules/statistics/components/dashboard-widget/DashboardWidgetStatistics.vue';

  import { useAuthentication } from '@/composables/auth/useAuthentication';
  import { useTheFooter } from '@/composables/framework/useTheFooter';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { config } from '@/config/config';
  import DashboardWidgetChecklists from '@/modules/checklists/components/dashboard-widget/DashboardWidgetChecklists.vue';
  import DashboardWidgetContinget from '@/modules/contingents/components/dashboard-widget/DashboardWidgetContinget.vue';
  import { i18n } from '@/plugins/i18n';
  import { useDashboardStore } from '@/store/useDashboardStore';
  import { storeToRefs } from 'pinia';
  import { computed, defineAsyncComponent, onMounted } from 'vue';

  const { locale, t } = i18n.global;

  const { userName } = useAuthentication();

  const store = useDashboardStore();
  const { loadData } = store;
  const { outstandingDebtInfo } = storeToRefs(store);
  const { checklistsInfo } = storeToRefs(store);
  const { statisticsInfo } = storeToRefs(store);

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(false);

  const theFooter = useTheFooter();
  theFooter.enabled(true);
  theFooter.visible(true);

  const logo = computed(() => {
    if (importedLogo) {
      return importedLogo;
    }
    return LogoCart;
  });

  const importedLogo = defineAsyncComponent({
    loader: () =>
      import(`../components/logo/LogoCart_${config.company.code}.vue`).catch((_error) => {
        return LogoCart;
      }),
    errorComponent: LogoCart,
  });

  onMounted(() => {
    loadData(locale.value);
  });
</script>
