<template>
  <AgModal v-if="internalValue" fit-width fit-height>
    <template #header>
      <div class="text-2xl font-bold">{{ t('customers.addNote') }}</div>
    </template>
    <template #content>
      <div class="flex flex-col gap-16 md:min-w-[333px]">
        <AgFormGroup :label="t('customers.noteDate')" required>
          <AgFormInput
            v-model="noteDate"
            :validation-state="noteDateValidationState"
            :error-message="t('customers.dateRequired')"
            type="datetime-local"
          />
        </AgFormGroup>
        <AgFormGroup :label="t('customers.category')">
          <AgFormSelect :options="categoryOptions" v-model="internalCategory" />
        </AgFormGroup>
        <AgFormGroup :label="t('customers.subject')" required>
          <AgFormInput
            v-model="subject"
            :validation-state="subjectValidationState"
            :error-message="t('customers.subjectRequired')"
            :max-lenght="50"
          />
        </AgFormGroup>
        <AgFormGroup :label="t('customers.note')" required>
          <AgFormTextarea
            v-model="note"
            :rows="10"
            :validation-state="noteValidationState"
            :error-message="t('customers.noteRequired')"
            :max-lenght="300"
            :resize="false"
          />
        </AgFormGroup>
      </div>
    </template>
    <template #footer>
      <!-- BUTTON -->
      <AgButton variant="secondary" @click="closeModal">{{ t('customers.cancel') }}</AgButton>
      <AgButton variant="primary" @click="addCustomerNote">{{ t('customers.apply') }}</AgButton>
    </template>
  </AgModal>
</template>
<script setup lang="ts">
  import type { AgFormSelectOption } from '@/components/library/form-select/AgFormSelectOption';
  import type { CustomerNotesRequest } from '@/domain/internal/CustomerNotesRequest';

  import AgButton from '@/components/library/button/AgButton.vue';
  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';
  import AgFormSelect from '@/components/library/form-select/AgFormSelect.vue';
  import AgFormTextarea from '@/components/library/form-textarea/AgFormTextarea.vue';
  import AgModal from '@/components/library/modal/AgModal.vue';

  import { useCustomerNotesStore } from '@/modules/customers/composables/useCustomerNotesStore';
  import { i18n } from '@/plugins/i18n';
  import { computed, ref, watch } from 'vue';

  const { t } = i18n.global;

  const store = useCustomerNotesStore();
  const { newCustomerNote } = store;

  interface Props {
    modelValue: boolean;
    customerId: number;
  }
  const props = withDefaults(defineProps<Props>(), {
    modelValue: false,
  });

  const categoryOptions: Array<AgFormSelectOption> = [
    { label: 'customers.visit', key: 'customers.visit' },
    { label: 'customers.contact', key: 'customers.contact' },
    { label: 'customers.general', key: 'customers.general' },
  ];

  const emit = defineEmits(['update:modelValue']);

  const noteDate = ref<string>('');
  const category = ref<string>('');
  const subject = ref<string>('');
  const note = ref<string>('');

  const noteDateValidationState = ref<boolean>(true);
  const categoryValidationState = ref<boolean>(true);
  const subjectValidationState = ref<boolean>(true);
  const noteValidationState = ref<boolean>(true);

  const internalValue = computed({
    get: () => props.modelValue,
    set: (newValue) => emit('update:modelValue', newValue),
  });

  const internalCategory = computed({
    get: () => {
      if (category.value) {
        if ('Besuch Visita Visit'.includes(category.value)) {
          return 'customers.visit';
        } else if ('Anruf/E-Mail Call/Email Telefonata/Email'.includes(category.value)) {
          return 'customers.contact';
        } else if ('Allgemein General Generale'.includes(category.value)) {
          return 'customers.general';
        }
      }
      return 'customers.visit';
    },
    set: (newCategory) => {
      category.value = newCategory;
    },
  });

  const closeModal = function () {
    internalValue.value = false;
  };

  function getTimestamp(date: number) {
    return Math.floor(date / 1000);
  }

  const addCustomerNote = async function () {
    if (!noteDate.value) {
      noteDateValidationState.value = false;
    } else {
      noteDateValidationState.value = true;
    }
    if (!category.value) {
      categoryValidationState.value = false;
    } else {
      categoryValidationState.value = true;
    }
    if (subject.value.length < 3) {
      subjectValidationState.value = false;
    } else {
      subjectValidationState.value = true;
    }
    if (note.value.length < 3) {
      noteValidationState.value = false;
    } else {
      noteValidationState.value = true;
    }
    if (
      !noteDateValidationState.value ||
      !categoryValidationState.value ||
      !subjectValidationState.value ||
      !noteValidationState.value
    ) {
      return;
    }
    const date = new Date(noteDate.value).getTime();
    const request: CustomerNotesRequest = {
      customerId: props.customerId,
      date: getTimestamp(date).toString(),
      category: t(category.value),
      subject: subject.value,
      note: note.value,
    };
    await newCustomerNote(request);

    noteDate.value = '';
    category.value = '';
    subject.value = '';
    note.value = '';

    closeModal();
  };

  watch(
    internalValue,
    () => {
      category.value = t('customers.visit');

      noteDateValidationState.value = true;
      categoryValidationState.value = true;
      subjectValidationState.value = true;
      noteValidationState.value = true;
    },
    { immediate: true }
  );
</script>
