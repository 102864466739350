<template>
  <AgModal v-if="internalValue" :has-footer="false" fit-height fit-width>
    <template #header>
      <div class="flex flex-row items-center justify-between">
        <span class="font-bold">
          {{ t('orders.stockInfo') }}
        </span>
        <AgButton variant="ghost" @click="internalValue = false" class="flex justify-end">
          <template #icon><IcClose class="h-24 w-24" /></template>
        </AgButton>
      </div>
    </template>
    <template #content>
      <div class="flex flex-col items-center justify-center h-full">
        <div v-if="isLoading">
          <IcLoading></IcLoading>
        </div>
        <span class="text-s-20 whitespace-pre-wrap" v-else-if="stockInfo && stockInfo.length > 0">
          {{ stockInfo }}
        </span>
        <span class="text-s-20 whitespace-pre-wrap" v-else-if="stockInfo === undefined">
          {{ t('orders.stockNotAvailable') }}
        </span>
        <span class="text-s-20 whitespace-pre-wrap" v-else-if="stockInfo !== undefined && stockInfo.length == 0">
          {{ t('orders.articleOffStock') }}
        </span>
      </div>
    </template>
  </AgModal>
</template>

<script setup lang="ts">
  import IcClose from '@/components/icons/IcClose.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgModal from '@/components/library/modal/AgModal.vue';

  import { useCommon } from '@/composables/useCommon';
  import { config } from '@/config/config';
  import { i18n } from '@/plugins/i18n';
  import { computed, ref, watch } from 'vue';

  const { t } = i18n.global;

  const { getArticleStock } = useCommon();

  interface Props {
    modelValue: boolean;
    articleCode: string;
  }
  const props = defineProps<Props>();

  const stockInfo = ref<string>();
  const isLoading = ref<boolean>(true);

  const emit = defineEmits(['update:modelValue']);

  const internalValue = computed({
    get: () => props.modelValue,
    set: (newValue) => emit('update:modelValue', newValue),
  });

  watch(
    internalValue,
    async (value) => {
      isLoading.value = true;
      if (value && config.company.enableStockAvailability && props.articleCode) {
        const articleStock = await getArticleStock(props.articleCode);
        stockInfo.value = '';
        if (articleStock) {
          articleStock
            .filter((stock) => stock.quantity > 0)
            .forEach((stock) => {
              stockInfo.value += `${stock.quantity} ${t('orders.stock')} ${stock.location}\n`;
            });
        } else {
          stockInfo.value = undefined;
        }
        isLoading.value = false;
      } else {
        stockInfo.value = undefined;
        isLoading.value = false;
      }
    },
    { immediate: true }
  );
</script>
