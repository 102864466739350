import { useApi } from '@/composables/api/useApi';
import { useMasterData } from '@/composables/data/useMasterData';
import { config } from '@/config/config';
import type { InvoiceNumberDto } from '@/domain/InvoiceNumberDto';
import type { StockAvailabilityDto } from '@/domain/StockAvailabilityDto';
import type { ContainerDto } from '@/domain/data/ContainerDto';
import { CustomerType } from '@/domain/enumeration/CustomerType';
import { DiscountType } from '@/domain/enumeration/DiscountType';
import type { ArticleDto } from '@/domain/masterData/ArticleDto';
import type { ArticleExternalCodeDto } from '@/domain/masterData/ArticleExternalCodeDto';
import type { ArticleGroupDto } from '@/domain/masterData/ArticleGroupDto';
import type { ArticleTypeDto } from '@/domain/masterData/ArticleTypeDto';
import type { DiscountDto } from '@/domain/masterData/DiscountDto';
import type { ShoppingCartArticleDto } from '@/domain/masterData/ShoppingCartArticleDto';
import type { TradeAgreementDto } from '@/domain/masterData/TradeAgreementDto';
import { i18n } from '@/plugins/i18n';
import { Optional } from '@/util/Optional';
import { useError } from '@/util/useError';
import { useTranslatedText } from './useTransalteText';

export function useCommon() {
  const masterData = useMasterData();
  const api = useApi();

  const { getTranslatedText } = useTranslatedText();
  const { handleCatchLocalError, handleCatchServerError } = useError();

  const { t } = i18n.global;

  const resolvePriceListCode = async function (priceListId?: number | null) {
    if (!priceListId) {
      return '';
    }
    const priceList = await masterData.tables.priceLists.get(priceListId);

    if (!priceList) {
      throw new Error(t('priceListNotFound'));
    }
    return priceList.code;
  };

  const getArticlesForCustomer = async (
    customerId: number | null,
    priceListId: number | null
  ): Promise<Array<ShoppingCartArticleDto>> => {
    const promotionsForAll = masterData.tables.tradeAgreements
      .where('customerType')
      .equals(CustomerType.PROMOTION)
      .filter(
        (t) => t.articleId !== null && t.customerId === null && t.priceListId === null && t.priceGroupId === null
      );
    const tradeAgreements = await Promise.all(
      [
        customerId
          ? await masterData.tables.tradeAgreements
              .where('[customerType+customerId]')
              .equals([CustomerType.CUSTOMER_ARTICLE, customerId])
              .toArray()
          : [],
        customerId
          ? await masterData.tables.tradeAgreements
              .where('[customerType+customerId]')
              .equals([CustomerType.CUSTOMER_PRICEGROUP, customerId])
              .toArray()
          : [],
        customerId
          ? await masterData.tables.tradeAgreements
              .where('[customerType+customerId]')
              .equals([CustomerType.PROMOTION, customerId])
              .toArray()
          : [],
        priceListId
          ? await masterData.tables.tradeAgreements
              .where('[customerType+priceListId]')
              .equals([CustomerType.PRICELIST_ARTICLE, priceListId])
              .toArray()
          : [],
        priceListId
          ? await masterData.tables.tradeAgreements
              .where('[customerType+priceListId]')
              .equals([CustomerType.PRICELIST_PRICEGROUP, priceListId])
              .toArray()
          : [],
        priceListId
          ? await masterData.tables.tradeAgreements
              .where('[customerType+priceListId]')
              .equals([CustomerType.PROMOTION, priceListId])
              .toArray()
          : [],
        await masterData.tables.tradeAgreements.where('customerType').equals(CustomerType.ALL).toArray(),
        await promotionsForAll.toArray(),
      ].flat()
    );
    const articleIds: Set<number> = new Set();
    const priceGroupIds: Set<number> = new Set();
    for (const tradeAgreement of tradeAgreements) {
      if (tradeAgreement.articleId) {
        articleIds.add(tradeAgreement.articleId);
      } else if (tradeAgreement.priceGroupId) {
        priceGroupIds.add(tradeAgreement.priceGroupId);
      }
    }
    const allArticles = await masterData.tables.articles.toArray();
    const otherArticles = allArticles.filter((article) => !articleIds.has(article.id));
    for (const article of otherArticles) {
      if (article.priceGroupIds.find((pgId) => priceGroupIds.has(pgId))) {
        articleIds.add(article.id);
      }
    }
    const shoppingCartArticles: ShoppingCartArticleDto[] = [];

    const eCodes = await masterData.tables.articleExternalCodes.toArray();
    const aGroups = await masterData.tables.articleGroups.toArray();
    const aTypes = await masterData.tables.articleTypes.toArray();

    const articles = allArticles.filter((article) => articleIds.has(article.id));
    for (const article of articles) {
      const articleshoppingCart = await articleToShoppingCartArticle(article, customerId, eCodes, aGroups, aTypes);
      shoppingCartArticles.push(articleshoppingCart);
    }
    return shoppingCartArticles.sort(
      ({ articleType: articleTypeA, title: titleA }, { articleType: articleTypeB, title: titleB }) =>
        getTranslatedText(articleTypeA?.title).localeCompare(getTranslatedText(articleTypeB?.title)) &&
        getTranslatedText(titleA).localeCompare(getTranslatedText(titleB))
    );
  };

  const articleToShoppingCartArticle = async function (
    article: ArticleDto,
    customerId?: number | null,
    externalCodes: ArticleExternalCodeDto[] = [],
    articleGroups: ArticleGroupDto[] = [],
    articleTypes: ArticleTypeDto[] = []
  ): Promise<ShoppingCartArticleDto> {
    return {
      id: article.id,
      code: article.code,

      externalCode:
        externalCodes.find((ec) => ec.articleId === article.id && ec.customerId === customerId)?.externalCode || '',

      type: article.type,
      unit: article.unit,
      searchName: article.searchName,
      sizeForNumber: article.sizeForNumber,
      sizeForWeight: article.sizeForWeight,
      contingentGroupId: article.contingentGroupId,
      articleTaxCode: article.articleTaxCode,
      title: article.title,
      infoTitle: article.infoTitle,
      attachmentsNumber: article.attachmentsNumber,

      articleGroup: articleGroups.find((ag) => ag.id === article.articleGroupId),
      articleType: articleTypes.find((at) => at.id === article.articleTypeId),

      packagings: article.packagings
        ? article.packagings.sort((pa, pb) =>
            getTranslatedText(pa.unit?.title).localeCompare(getTranslatedText(pb.unit?.title))
          )
        : [],

      articleDimensionValueConfigIds: article.articleDimensionValueConfigIds,
      defaultDimensionValueConfigIds: article.defaultDimensionValueConfigIds,
      priceGroupIds: article.priceGroupIds,
    };
  };

  const getHeadDiscount = async function (customerId: number): Promise<Optional<DiscountDto>> {
    if (customerId) {
      const customerDiscount = await masterData.tables.discounts
        .where('[type+customerType+customerId]')
        .equals([DiscountType.HEAD, CustomerType.CUSTOMER, customerId])
        .first();

      if (customerDiscount) {
        return Optional.of(customerDiscount);
      }
    }
    return Optional.empty();
  };

  const getPositionDiscounts = async function (
    articleId: number,
    priceGroupId: number | null,
    customerId: number | null,
    priceListId: number | null,
    discountListId: number | null,
    fromQt?: number
  ) {
    const foundDiscounts: DiscountDto[] = [];

    let tmpds = [];
    if (customerId) {
      const discounts = masterData.tables.discounts
        .where('[type+customerType+customerId+articleId]')
        .equals([DiscountType.POSITION, CustomerType.PROMOTION, customerId, articleId]);

      if (fromQt !== undefined) {
        discounts.and((discount) => discount.fromQuantity <= fromQt);
      }
      tmpds = await discounts.toArray();

      if (tmpds.length > 0) {
        foundDiscounts.push(...tmpds);
      }
      if (priceGroupId) {
        const discounts = masterData.tables.discounts
          .where('[type+customerType+customerId+priceGroupId]')
          .equals([DiscountType.POSITION, CustomerType.PROMOTION, customerId, priceGroupId]);

        if (fromQt !== undefined) {
          discounts.and((discount) => discount.fromQuantity <= fromQt);
        }
        tmpds = await discounts.toArray();

        if (tmpds.length > 0) {
          foundDiscounts.push(...tmpds);
        }
      }
    }
    if (priceListId) {
      const priceListPromotionDiscounts = masterData.tables.discounts
        .where('[type+customerType+priceListId+articleId]')
        .equals([DiscountType.POSITION, CustomerType.PROMOTION, priceListId, articleId]);

      if (fromQt !== undefined) {
        priceListPromotionDiscounts.and((discount) => discount.fromQuantity <= fromQt);
      }
      tmpds = await priceListPromotionDiscounts.toArray();

      if (tmpds.length > 0) {
        foundDiscounts.push(...tmpds);
      }
      if (priceGroupId) {
        const discounts = masterData.tables.discounts
          .where('[type+customerType+priceListId+priceGroupId]')
          .equals([DiscountType.POSITION, CustomerType.PROMOTION, priceListId, priceGroupId]);

        if (fromQt !== undefined) {
          discounts.and((discount) => discount.fromQuantity <= fromQt);
        }
        tmpds = await discounts.toArray();

        if (tmpds.length > 0) {
          foundDiscounts.push(...tmpds);
        }
      }
    }
    const articlePromotionDiscounts = masterData.tables.discounts
      .where('[type+customerType+articleId]')
      .equals([DiscountType.POSITION, CustomerType.PROMOTION, articleId]);

    if (fromQt !== undefined) {
      articlePromotionDiscounts.and((discount) => discount.fromQuantity <= fromQt);
    }
    tmpds = await articlePromotionDiscounts.toArray();

    if (tmpds.length > 0) {
      foundDiscounts.push(...tmpds);
    }

    if (customerId) {
      const discounts = masterData.tables.discounts
        .where('[type+customerType+customerId+articleId]')
        .equals([DiscountType.POSITION, CustomerType.CUSTOMER_ARTICLE, customerId, articleId]);

      if (fromQt !== undefined) {
        discounts.and((discount) => discount.fromQuantity <= fromQt);
      }
      tmpds = await discounts.toArray();

      if (tmpds.length > 0) {
        foundDiscounts.push(...tmpds);
      }
      if (priceGroupId) {
        const discounts = masterData.tables.discounts
          .where('[type+customerType+customerId+priceGroupId]')
          .equals([DiscountType.POSITION, CustomerType.CUSTOMER_PRICEGROUP, customerId, priceGroupId]);

        if (fromQt !== undefined) {
          discounts.and((discount) => discount.fromQuantity <= fromQt);
        }
        tmpds = await discounts.toArray();

        if (tmpds.length > 0) {
          foundDiscounts.push(...tmpds);
        }
      }
      const customerDiscounts = masterData.tables.discounts
        .where('[type+customerType+customerId]')
        .equals([DiscountType.POSITION, CustomerType.CUSTOMER, customerId]);

      if (fromQt !== undefined) {
        customerDiscounts.and((discount) => discount.fromQuantity <= fromQt);
      }
      tmpds = await customerDiscounts.toArray();

      if (tmpds.length > 0) {
        foundDiscounts.push(...tmpds);
      }
    }
    if (discountListId) {
      const discounts = masterData.tables.discounts
        .where('[type+customerType+discountListId+articleId]')
        .equals([DiscountType.POSITION, CustomerType.DISCOUNTLIST_ARTICLE, discountListId, articleId]);

      if (fromQt !== undefined) {
        discounts.and((discount) => discount.fromQuantity <= fromQt);
      }
      tmpds = await discounts.toArray();

      if (tmpds.length > 0) {
        foundDiscounts.push(...tmpds);
      }
      if (priceGroupId) {
        const discounts = masterData.tables.discounts
          .where('[type+customerType+discountListId+priceGroupId]')
          .equals([DiscountType.POSITION, CustomerType.DISCOUNTLIST_PRICEGROUP, discountListId, priceGroupId]);

        if (fromQt !== undefined) {
          discounts.and((discount) => discount.fromQuantity <= fromQt);
        }
        tmpds = await discounts.toArray();

        if (tmpds.length > 0) {
          foundDiscounts.push(...tmpds);
        }
      }
      const discountList = masterData.tables.discounts
        .where('[type+customerType+discountListId]')
        .equals([DiscountType.POSITION, CustomerType.DISCOUNTLIST, discountListId]);

      if (fromQt !== undefined) {
        discountList.and((discount) => discount.fromQuantity <= fromQt);
      }
      tmpds = await discountList.toArray();

      if (tmpds.length > 0) {
        foundDiscounts.push(...tmpds);
      }
    }
    if (priceListId) {
      const priceListArticleDiscounts = masterData.tables.discounts
        .where('[type+customerType+priceListId+articleId]')
        .equals([DiscountType.POSITION, CustomerType.PRICELIST_ARTICLE, priceListId, articleId]);

      if (fromQt !== undefined) {
        priceListArticleDiscounts.and((discount) => discount.fromQuantity <= fromQt);
      }
      tmpds = await priceListArticleDiscounts.toArray();

      if (tmpds.length > 0) {
        foundDiscounts.push(...tmpds);
      }
      if (priceGroupId) {
        const discounts = masterData.tables.discounts
          .where('[type+customerType+priceListId+priceGroupId]')
          .equals([DiscountType.POSITION, CustomerType.PRICELIST_PRICEGROUP, priceListId, priceGroupId]);

        if (fromQt !== undefined) {
          discounts.and((discount) => discount.fromQuantity <= fromQt);
        }
        tmpds = await discounts.toArray();

        if (tmpds.length > 0) {
          foundDiscounts.push(...tmpds);
        }
      }
      const priceListDiscounts = masterData.tables.discounts
        .where('[type+customerType+priceListId]')
        .equals([DiscountType.POSITION, CustomerType.PRICELIST, priceListId]);

      if (fromQt !== undefined) {
        priceListDiscounts.and((discount) => discount.fromQuantity <= fromQt);
      }
      tmpds = await priceListDiscounts.toArray();

      if (tmpds.length > 0) {
        foundDiscounts.push(...tmpds);
      }
    }
    const allDiscounts = masterData.tables.discounts
      .where('[type+customerType+articleId]')
      .equals([DiscountType.POSITION, CustomerType.ALL, articleId]);

    if (fromQt !== undefined) {
      allDiscounts.and((discount) => discount.fromQuantity <= fromQt);
    }
    tmpds = await allDiscounts.toArray();

    if (tmpds.length > 0) {
      foundDiscounts.push(...tmpds);
    }
    if (priceGroupId) {
      const discounts = masterData.tables.discounts
        .where('[type+customerType+priceGroupId]')
        .equals([DiscountType.POSITION, CustomerType.PRICEGROUP, priceGroupId]);

      if (fromQt !== undefined) {
        discounts.and((discount) => discount.fromQuantity <= fromQt);
      }
      tmpds = await discounts.toArray();

      if (tmpds.length > 0) {
        foundDiscounts.push(...tmpds);
      }
    }
    return foundDiscounts;
  };

  const getTradeAgreements = async function (
    articleId: number,
    priceGroupIds: Array<number>,
    customerId: number | null,
    priceListId: number | null
  ) {
    const tradeAgreements: TradeAgreementDto[] = [];
    let ta = customerId
      ? await masterData.tables.tradeAgreements
          .where('[customerType+customerId+articleId]')
          .equals([CustomerType.PROMOTION, customerId, articleId])
          .toArray()
      : [];

    if (ta.length > 0) {
      tradeAgreements.push(...ta);
    }
    if (priceGroupIds && priceGroupIds.length > 0) {
      for (const pgId of priceGroupIds) {
        ta = customerId
          ? await masterData.tables.tradeAgreements
              .where('[customerType+customerId+priceGroupId]')
              .equals([CustomerType.PROMOTION, customerId, pgId])
              .toArray()
          : [];

        if (ta.length > 0) {
          tradeAgreements.push(...ta);
        }
      }
    }
    if (priceListId) {
      ta = await masterData.tables.tradeAgreements
        .where('[customerType+priceListId+articleId]')
        .equals([CustomerType.PROMOTION, priceListId, articleId])
        .toArray();

      if (ta.length > 0) {
        tradeAgreements.push(...ta);
      }
      if (priceGroupIds && priceGroupIds.length > 0) {
        for (const pgId of priceGroupIds) {
          ta = await masterData.tables.tradeAgreements
            .where('[customerType+priceListId+priceGroupId]')
            .equals([CustomerType.PROMOTION, priceListId, pgId])
            .toArray();

          if (ta.length > 0) {
            tradeAgreements.push(...ta);
          }
        }
      }
    }
    const taCollection = masterData.tables.tradeAgreements
      .where('[customerType+articleId]')
      .equals([CustomerType.PROMOTION, articleId])
      .filter((ta) => ta.customerId === null && ta.priceListId === null && ta.priceGroupId === null);

    ta = await taCollection.toArray();
    if (ta.length > 0) {
      tradeAgreements.push(...ta);
    }
    ta = customerId
      ? await masterData.tables.tradeAgreements
          .where('[customerType+customerId+articleId]')
          .equals([CustomerType.CUSTOMER_ARTICLE, customerId, articleId])
          .toArray()
      : [];

    if (ta.length > 0) {
      tradeAgreements.push(...ta);
    }
    if (priceGroupIds && priceGroupIds.length > 0) {
      for (const pgId of priceGroupIds) {
        ta = customerId
          ? await masterData.tables.tradeAgreements
              .where('[customerType+customerId+priceGroupId]')
              .equals([CustomerType.CUSTOMER_PRICEGROUP, customerId, pgId])
              .toArray()
          : [];

        if (ta.length > 0) {
          tradeAgreements.push(...ta);
        }
      }
    }
    if (priceListId) {
      ta = await masterData.tables.tradeAgreements
        .where('[customerType+priceListId+articleId]')
        .equals([CustomerType.PRICELIST_ARTICLE, priceListId, articleId])
        .toArray();

      if (ta.length > 0) {
        tradeAgreements.push(...ta);
      }
      if (priceGroupIds && priceGroupIds.length > 0) {
        for (const pgId of priceGroupIds) {
          const ta = await masterData.tables.tradeAgreements
            .where('[customerType+priceListId+priceGroupId]')
            .equals([CustomerType.PRICELIST_PRICEGROUP, priceListId, pgId])
            .toArray();

          if (ta.length > 0) {
            tradeAgreements.push(...ta);
          }
        }
      }
    }
    ta = await masterData.tables.tradeAgreements
      .where('[customerType+articleId]')
      .equals([CustomerType.ALL, articleId])
      .toArray();

    if (ta.length > 0) {
      tradeAgreements.push(...ta);
    }
    return tradeAgreements;
  };

  const getExternalCode = async function (articleId?: number, customerId?: number | null) {
    if (!articleId || !customerId) {
      return '';
    }
    const exCode = await masterData.tables.articleExternalCodes
      .where('[articleId+customerId]')
      .equals([articleId, customerId])
      .first();

    return exCode?.externalCode || '';
  };

  const getArticleGroup = async function (articleGroupId?: number) {
    if (!articleGroupId) {
      return;
    }
    return await masterData.tables.articleGroups.get(articleGroupId);
  };

  const getArticleType = async function (articleTypeId?: number) {
    if (!articleTypeId) {
      return;
    }
    return await masterData.tables.articleTypes.get(articleTypeId);
  };

  // /outstandingdebts/overdue/{customerId}?app_version=2023.1.4512813617
  const getInvoiceNumber = async function (customerId?: number | null): Promise<InvoiceNumberDto | null> {
    let invoiceNumber: InvoiceNumberDto | null = null;

    try {
      if (!customerId) {
        return invoiceNumber;
      }
      const params = {
        app_version: '2023.1.4512813617',
      };
      const searchParams = new URLSearchParams(params);
      const requestUri = `/outstandingdebts/overdue/${customerId}?${searchParams}`;

      const container = await api.fetch<ContainerDto>(requestUri);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return null;
      }
      if (container.data.invoiceNumber) {
        invoiceNumber = container.data.invoiceNumber;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
    return invoiceNumber;
  };

  // /stock/article/{articleCode}?app_version=2023.1.4512813617
  const getArticleStock = async function (articleCode?: string | null): Promise<Array<StockAvailabilityDto> | null> {
    const stockAvailability: Array<StockAvailabilityDto> = [];
    try {
      if (!articleCode) {
        return stockAvailability;
      }
      const params = {
        app_version: '2023.1.4512813617',
      };
      const searchParams = new URLSearchParams(params);
      const requestUri = `/stock/article/${articleCode}?${searchParams}`;

      const container = await api.fetch<ContainerDto>(requestUri);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return null;
      }
      if (container.data.articleStock) {
        stockAvailability.push(...container.data.articleStock);
      }
    } catch (error) {
      handleCatchLocalError(error);
      return null;
    }
    return stockAvailability;
  };

  // /addphotos/gid/{position}?app_version=2023.1.4512813617
  const savePhoto = async function (file: File) {
    try {
      const params = {
        app_version: '2023.1.4512813617',
      };
      const searchParams = new URLSearchParams(params);
      const requestUri = `/photo/add?${searchParams}`;
      const body = new FormData();
      body.set('photo', file);

      const container = await api.fetch<ContainerDto>(requestUri, 'POST', body);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  async function openFile(photoName: string, download = false): Promise<Blob | undefined> {
    try {
      const url = `${config.backend.url}/photo/download/${photoName}`;
      const blob = await api.download(url);

      if (blob instanceof Blob) {
        if (download) {
          const downloadUrl = window.URL.createObjectURL(blob);

          const link = document.createElement('a');
          link.href = downloadUrl;
          link.download = photoName;
          link.click();
          setTimeout(function () {
            window.URL.revokeObjectURL(downloadUrl);
          }, 333);
        }
        return blob;
      }
      return undefined;
    } catch (e) {
      handleCatchLocalError(e);
    }
  }

  async function deletePhoto(photoName: string) {
    try {
      const container = await api.fetch<ContainerDto>(
        `/photo/delete/${photoName}?app_version=2023.1.4512813617`,
        'DELETE'
      );
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
    } catch (e) {
      handleCatchLocalError(e);
    }
  }

  async function getMinPriceByArticle(articleId: number) {
    const article = await masterData.tables.articles.get(articleId);
    return article?.minPrice ?? 0;
  }

  async function getArticleName(contingentGroupArticleId: number) {
    const article = await masterData.tables.articles
      .where('[contingentGroupId]')
      .equals([contingentGroupArticleId])
      .first();

    return article?.title['de'] || '';
  }
  const getArticleInfo = async function (articleId: number) {
    const article = await masterData.tables.articles.get(articleId);
    return article ? article.infoTitle : {};
  };

  return {
    resolvePriceListCode,
    getArticlesForCustomer,
    getHeadDiscount,
    getPositionDiscounts,
    getTradeAgreements,
    getExternalCode,
    getArticleGroup,
    getArticleType,
    getInvoiceNumber,
    getArticleStock,
    savePhoto,
    openFile,
    deletePhoto,
    getMinPriceByArticle,
    getArticleName,
    getArticleInfo,
  };
}
