<template>
  <div v-if="offer" class="md:mx-[80px] max-md:mx-16 mt-12 mb-48 min-w-fit">
    <OfferInfo />

    <div v-if="isNewCustomer()" class="flex gap-4 items-center">
      <div class="flex gap-4" @click="(offer.customer.isPrivate = false)">
        <input
          type="radio"
          id="company"
          name="company"
          v-model="offer.customer.isPrivate"
          :value="false"
          class="hover:cursor-pointer"
        />
        <label for="company" class="font-bold cursor-pointer">
          {{ t('offers.company') }}
        </label>
      </div>

      <div class="flex gap-4" @click="(offer.customer.isPrivate = true)">
        <input
          type="radio"
          id="privateCustomer"
          name="privateCustomer"
          :value="true"
          v-model="offer.customer.isPrivate"
          class="hover:cursor-pointer"
        />
        <label for="privateCustomer" class="font-bold cursor-pointer">
          {{ t('offers.isPrivateCustomer') }}
        </label>
      </div>
    </div>

    <div v-if="offer.customer.isPrivate" class="flex flex-row gap-12">
      <AgFormGroup class="basis-1/3" :label="t('offers.customerName')" required>
        <AgFormInput
          v-model="customerName"
          :validation-state="validationCustomer.name.state"
          :errorMessage="validationCustomer.name.msg"
          :disabled="isDisable"
          @input="(customerName = customerName.toUpperCase())"
        />
      </AgFormGroup>
      <AgFormGroup class="basis-1/3" :label="t('offers.surname')" required>
        <AgFormInput
          v-model="customerSurname"
          :validation-state="validationCustomer.surname.state"
          :errorMessage="validationCustomer.surname.msg"
          :disabled="isDisable"
          @input="(customerSurname = customerSurname.toUpperCase())"
        />
      </AgFormGroup>
      <AgFormGroup class="basis-1/3" :label="t('offers.alternativeName')">
        <AgFormInput
          v-model="offer.customer.alternativeName"
          @input="(offer.customer.alternativeName = (offer.customer.alternativeName ?? '').toLocaleUpperCase())"
        />
      </AgFormGroup>
    </div>
    <div v-else class="flex flex-row gap-12">
      <AgFormGroup class="basis-1/2" :label="t('offers.companyName')" required>
        <AgFormInput
          v-model="offer.customer.name"
          :validation-state="validationCustomer.name.state"
          :errorMessage="validationCustomer.name.msg"
          :disabled="isDisable"
          @input="(offer.customer.name = offer.customer.name.toUpperCase())"
        />
      </AgFormGroup>
      <AgFormGroup class="basis-1/2" :label="t('orders.alternativeName')">
        <AgFormInput v-model="offer.customer.alternativeName" />
      </AgFormGroup>
    </div>

    <div class="flex gap-12 max-md:flex-col py-4">
      <!-- CUSTOMER FISCAL CODE -->
      <AgFormGroup :class="privateCustomerClasses('basis-1/3')" :label="t('offers.fiscalCode')">
        <AgFormInput
          v-model="offer.customer.fiscalCode"
          :validation-state="validationCustomer.fiscalCode.state"
          :errorMessage="validationCustomer.fiscalCode.msg"
          :disabled="isDisable"
          :required="offer.customer.isPrivate"
          @input="(offer.customer.fiscalCode = offer.customer.fiscalCode.toUpperCase())"
        />
      </AgFormGroup>
      <!-- CUSTOMER VAT CODE -->
      <AgFormGroup v-if="!offer.customer.isPrivate" class="basis-1/3" :label="t('offers.vatCode')" required>
        <AgFormInput
          v-model="offer.customer.vatCode"
          :validation-state="validationCustomer.vatCode.state"
          :errorMessage="validationCustomer.vatCode.msg"
          :disabled="isDisable"
          @input="(offer.customer.vatCode = offer.customer.vatCode.toUpperCase())"
        />
      </AgFormGroup>
      <!-- CUSTOMER WEBSITE URL -->
      <AgFormGroup v-if="!offer.customer.isPrivate" class="basis-1/3" :label="t('orders.website')">
        <AgFormInput
          v-model="offer.customer.webSiteUrl"
          :validation-state="validationCustomer.webSiteUrl.state"
          :errorMessage="validationCustomer.webSiteUrl.msg"
        />
      </AgFormGroup>
    </div>

    <div class="flex gap-12 max-md:flex-col py-4">
      <!-- ADDRESS STREET -->
      <AgFormGroup class="basis-1/3" :label="t('offers.street')" required>
        <AgFormInput
          v-model="offer.invoiceAddress.street"
          :validation-state="validationAddress.street.state"
          :errorMessage="validationAddress.street.msg"
        />
      </AgFormGroup>
      <!-- ADDRESS CITY -->
      <AgFormGroup class="basis-1/3" :label="t('offers.city')" required>
        <AgFormInput
          v-model="offer.invoiceAddress.city"
          :validation-state="validationAddress.city.state"
          :errorMessage="validationAddress.city.msg"
        />
      </AgFormGroup>
      <!-- ADDRESS DISTRICT -->
      <AgFormGroup class="basis-1/3" :label="t('offers.district')">
        <AgFormInput v-model="offer.invoiceAddress.district" />
      </AgFormGroup>
    </div>

    <div class="flex max-sm:flex-col gap-12 py-4">
      <!-- COUNTRY -->
      <AgFormGroup class="basis-2/3" :label="t('offers.country')" required>
        <AgSearchSelect
          :options="countryOptions"
          v-model="offer.invoiceAddress.nation"
          :validation-state="validationAddress.nation.state"
          :errorMessage="validationAddress.nation.msg"
        />
      </AgFormGroup>
      <!-- ZIP CODE -->
      <AgFormGroup class="basis-1/3" :label="t('offers.zipCode')" required>
        <AgFormInput
          v-model="offer.invoiceAddress.zip"
          :validation-state="validationAddress.zip.state"
          :errorMessage="validationAddress.zip.msg"
        />
      </AgFormGroup>
      <!-- ADDRESS REGION -->
      <AgFormGroup class="basis-1/3" :label="t('offers.region')" required>
        <AgFormInput
          v-model="offer.invoiceAddress.region"
          :validation-state="validationAddress.region.state"
          :errorMessage="validationAddress.region.msg"
        />
      </AgFormGroup>
    </div>

    <div class="flex gap-12 py-4 max-md:flex-col">
      <!-- PHONE -->
      <AgFormGroup class="basis-1/3" :label="t('offers.phone')" required>
        <AgFormInput
          type="tel"
          v-model="offer.invoiceAddress.phoneNumber"
          :validation-state="validationAddress.phone.state"
          :errorMessage="validationAddress.phone.msg"
        />
      </AgFormGroup>
      <!-- MAIL -->
      <AgFormGroup class="basis-1/3" :label="t('offers.mail')" required>
        <AgFormInput
          type="email"
          v-model="offer.invoiceAddress.email"
          :validation-state="validationAddress.email.state"
          :errorMessage="validationAddress.email.msg"
        />
      </AgFormGroup>
      <!-- PEC -->
      <AgFormGroup class="basis-1/3" :label="t('offers.pecMail')">
        <AgFormInput
          type="email"
          v-model="offer.invoiceAddress.pec"
          :validation-state="validationAddress.pec.state"
          :errorMessage="validationAddress.pec.msg"
        />
      </AgFormGroup>
    </div>
    <div class="flex gap-12 py-4 max-sm:flex-col">
      <!-- CUSTOMER LANGUAGE -->
      <AgFormGroup class="basis-1/2" :label="t('offers.languageCustomer')" required>
        <AgSearchSelect
          v-model="offer.customer.language"
          :options="langOptions"
          :validation-state="validationCustomer.language.state"
          :errorMessage="validationCustomer.language.msg"
        />
      </AgFormGroup>
      <!-- BUSINESS CATEGORY -->
      <AgFormGroup class="basis-1/2" :label="t('offers.section')" required>
        <AgSearchSelect :options="businessCategoryOptions" v-model="selectedBusinessCategory" />
      </AgFormGroup>
    </div>

    <div class="flex gap-12 py-4 max-md:flex-col">
      <!-- CUSTOMER SDI CODE -->
      <AgFormGroup v-if="!offer.customer.isPrivate" class="basis-1/2" :label="t('offers.sdiCode')">
        <AgFormInput
          v-model="offer.customer.sdiCode"
          :validation-state="validationCustomer.sdiCode.state"
          :errorMessage="validationCustomer.sdiCode.msg"
        />
      </AgFormGroup>
      <!-- CUSTOMER IBAN -->
      <AgFormGroup :class="privateCustomerClasses('basis-1/2')" label="IBAN">
        <AgFormInput
          v-model="offer.customer.iban"
          :validation-state="validationCustomer.iban.state"
          :errorMessage="validationCustomer.iban.msg"
        />
      </AgFormGroup>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { AgSearchSelectOption } from '@/components/library/search-select/AgSearchSelectOption';
  import type { CountryDto } from '@/domain/masterData/CountryDto';
  import type { OfferBusinessCategoryDto } from '@/domain/offerData/OfferBusinessCategoryDto';

  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';
  import AgSearchSelect from '@/components/library/search-select/AgSearchSelect.vue';
  import OfferInfo from '@/modules/offers/components/offer-wizard/steps/OfferInfo.vue';

  import { useMasterData } from '@/composables/data/useMasterData';
  import { useTheFooter } from '@/composables/framework/useTheFooter';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { useTranslatedText } from '@/composables/useTransalteText';
  import { config } from '@/config/config';
  import {
    isAddressValid,
    isCustomerValid,
    validateAddressForm,
    validateCustomerForm,
    validatePrivateCustomerForm,
  } from '@/modules/offers/components/offer-wizard/steps/customer/CustomerValidation';
  import { useOfferWizardStore } from '@/modules/offers/stores/useOfferWizardStore';
  import { i18n } from '@/plugins/i18n';
  import { storeToRefs } from 'pinia';
  import { computed, onBeforeMount, onMounted, ref, watch } from 'vue';

  const { t } = i18n.global;

  const { getTranslatedText } = useTranslatedText();

  const offerStore = useOfferWizardStore();
  const { offer } = storeToRefs(offerStore);

  const { tables } = useMasterData();

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.title('offers.offer', 'offers.customerWizard');

  const theFooter = useTheFooter();
  theFooter.enabled(false);
  theFooter.visible(false);

  const businessCategories = ref<Array<OfferBusinessCategoryDto>>([]);
  const countries = ref<Array<CountryDto>>([]);

  const customerName = ref<string>('');
  const customerSurname = ref<string>('');

  const isNewCustomer = function () {
    if (!offer.value) {
      return false;
    }
    return offer.value.customer.id === null;
  };

  const privateCustomerClasses = function (cls: string) {
    return {
      'w-full': offer.value?.customer.isPrivate,
      [cls]: !offer.value?.customer.isPrivate,
    };
  };

  const emit = defineEmits(['update:modelValue']);

  const isDisable = computed(() => !!offer.value?.customer.id);
  const selectedBusinessCategory = computed({
    get: () => {
      const id = offer.value?.invoiceAddress.businessCategory?.id;
      if (id && businessCategories.value.some((bc) => bc.id === id)) {
        return id;
      } else if (businessCategories.value.length > 0) {
        return businessCategories.value[0].id;
      }
    },
    set: (businessCategoryId) => {
      const businessCategory = businessCategories.value.find((bc) => bc.id === businessCategoryId);
      if (!businessCategory || !offer.value) {
        return;
      }
      offer.value.invoiceAddress.businessCategory = businessCategory;
      offer.value.invoiceAddress.businessCategoryId = businessCategory.id;
    },
  });

  const loadData = async function () {
    businessCategories.value = await tables.businessCategories.toArray();
    countries.value = await tables.countries.toArray();
  };

  const businessCategoryOptions = computed((): Array<AgSearchSelectOption> => {
    return businessCategories.value.map((bc) => {
      return {
        value: bc.id,
        label: getTranslatedText(bc.title).charAt(0).toUpperCase() + getTranslatedText(bc.title).slice(1),
        searchableString: getTranslatedText(bc.title),
      };
    });
  });

  const countryOptions = computed((): Array<AgSearchSelectOption> => {
    return countries.value.map((c) => {
      return {
        value: c.code,
        label: getTranslatedText(c.title).toUpperCase(),
        searchableString: getTranslatedText(c.title),
      };
    });
  });

  const langOptions = ref<Array<AgSearchSelectOption>>(
    Object.values(config.company.availableLanguages).map((lang) => ({
      value: lang,
      label: t(`offers.language.${lang}`),
      searchableString: t(`offers.language.${lang}`),
    }))
  );

  const validationCustomer = computed(() => {
    validateAll();
    const isPrivateCustomer = offer.value?.customer.isPrivate;
    if (isPrivateCustomer) {
      return validatePrivateCustomerForm(
        offer.value?.customer,
        offer.value?.paymentMethod ?? null,
        customerName.value,
        customerSurname.value
      );
    }
    return validateCustomerForm(offer.value?.customer, offer.value?.paymentMethod ?? null);
  });

  const validationAddress = computed(() => {
    validateAll();
    return validateAddressForm(offer.value?.invoiceAddress, countries.value);
  });

  const validateAll = function () {
    emit('update:modelValue', {
      id: 0,
      error:
        isAddressValid(offer.value?.invoiceAddress, countries.value) &&
        isCustomerValid(
          offer.value?.customer,
          offer.value?.paymentMethod ?? null,
          offer.value?.customer.isPrivate ?? false,
          customerName.value,
          customerSurname.value
        ),
    });
  };
  const getCustomer = async function () {
    const code = offer.value?.customer.code ?? '';
    const customer = await tables.customers.where('code').equals(code).first();
    return customer;
  };

  watch(
    [customerName, customerSurname],
    () => {
      if (offer.value && offer.value.customer.isPrivate) {
        if (customerName.value || customerSurname.value) {
          offer.value.customer.name = customerName.value + ',' + customerSurname.value;
        }
      }
    },
    { immediate: true }
  );

  onBeforeMount(async () => {
    await loadData();
  });

  onMounted(async () => {
    if (offer.value && offer.value.customer.isPrivate) {
      const customerFullName = offer.value.customer.name;
      customerName.value = customerFullName.split(',')[0];
      customerSurname.value = customerFullName.split(',')[1];
    }
  });
</script>

<style>
  /* Hide arrows on input type number*/
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
</style>
