import type { DiscountDto } from '@/domain/masterData/DiscountDto';
import type { TradeAgreementDto } from '@/domain/masterData/TradeAgreementDto';

import { config } from '@/config/config';
import { CustomerType } from '@/domain/enumeration/CustomerType';
import { DiscountType } from '@/domain/enumeration/DiscountType';
import { SegmentType } from '@/domain/enumeration/SegmentType';
import { useMasterData } from './data/useMasterData';

export function useNordwal() {
  const masterData = useMasterData();

  const getPositionDiscounts = async function (
    customerType: CustomerType,
    articleId: number,
    priceGroupId: number | null,
    customerId: number | null,
    priceListId: number | null,
    quantity?: number,
    fromQt?: number,
    toQt?: number
  ) {
    if (customerId) {
      const discounts = masterData.tables.discounts
        .where('[type+customerType+customerId+articleId]')
        .equals([DiscountType.POSITION, customerType, customerId, articleId]);

      if (quantity !== undefined) {
        discounts.and((discount) => discount.fromQuantity <= quantity);
      } else if (fromQt !== undefined && toQt !== undefined) {
        discounts.and((discount) => discount.fromQuantity >= fromQt && discount.fromQuantity < toQt);
      } else if (fromQt != undefined) {
        discounts.and((discount) => discount.fromQuantity >= fromQt);
      }
      const ds = await discounts.toArray();
      if (ds.length > 0) {
        return ds;
      }
      if (priceGroupId) {
        const discounts = masterData.tables.discounts
          .where('[type+customerType+customerId+priceGroupId]')
          .equals([DiscountType.POSITION, customerType, customerId, priceGroupId]);

        if (quantity !== undefined) {
          discounts.and((discount) => discount.fromQuantity <= quantity);
        } else if (fromQt !== undefined && toQt !== undefined) {
          discounts.and((discount) => discount.fromQuantity >= fromQt && discount.fromQuantity < toQt);
        } else if (fromQt != undefined) {
          discounts.and((discount) => discount.fromQuantity >= fromQt);
        }
        const foundDiscount = await discounts.toArray();
        if (foundDiscount.length > 0) {
          ds.push(...foundDiscount);
        }
        if (ds.length > 0) {
          return ds;
        }
      }
    }
    if (priceListId) {
      const priceListArticleDiscounts = masterData.tables.discounts
        .where('[type+customerType+priceListId+articleId]')
        .equals([DiscountType.POSITION, customerType, priceListId, articleId]);

      if (quantity !== undefined) {
        priceListArticleDiscounts.and((discount) => discount.fromQuantity <= quantity);
      } else if (fromQt !== undefined && toQt !== undefined) {
        priceListArticleDiscounts.and((discount) => discount.fromQuantity >= fromQt && discount.fromQuantity < toQt);
      } else if (fromQt != undefined) {
        priceListArticleDiscounts.and((discount) => discount.fromQuantity >= fromQt);
      }
      const ds = await priceListArticleDiscounts.toArray();
      if (ds.length > 0) {
        return ds;
      }
      if (priceGroupId) {
        const discounts = masterData.tables.discounts
          .where('[type+customerType+priceListId+priceGroupId]')
          .equals([DiscountType.POSITION, customerType, priceListId, priceGroupId]);

        if (quantity !== undefined) {
          discounts.and((discount) => discount.fromQuantity <= quantity);
        } else if (fromQt !== undefined && toQt !== undefined) {
          discounts.and((discount) => discount.fromQuantity >= fromQt && discount.fromQuantity < toQt);
        } else if (fromQt != undefined) {
          discounts.and((discount) => discount.fromQuantity >= fromQt);
        }
        const foundDiscount = await discounts.toArray();
        if (foundDiscount.length > 0) {
          ds.push(...foundDiscount);
        }
        if (ds.length > 0) {
          return ds;
        }
      }
    }
    return [];
  };

  const getBaseTradeAgreements = async function (articleId: number, segment: string | null) {
    const tradeAgreements = await masterData.tables.tradeAgreements
      .where('[customerType+articleId]')
      .equals([CustomerType.ALL, articleId])
      .toArray();

    if (tradeAgreements.length > 0) {
      const tas = tradeAgreements.filter((ta) => filterBySegment(ta, segment));
      if (tas.length > 0) {
        return tas[0];
      }
    }
    return null;
  };

  const filterBySegment = function (ta: TradeAgreementDto, segment: string | null) {
    if (ta.salesPrice === 0 && ta.industrialPrice === 0) {
      return true;
    }
    if (segment && segment === SegmentType.INDUSTRIAL) {
      return ta.industrialPrice > 0;
    }
    return ta.salesPrice > 0;
  };

  const getFinalDiscount = function (ta: TradeAgreementDto, ds: DiscountDto[], segment: string | null, qt: number) {
    if (ds.length === 1) {
      return ds[0];
    }
    let finalDiscount: DiscountDto | undefined = undefined;

    const discounts = ds.sort((d1, d2) => d1.fromQuantity - d2.fromQuantity);

    const discountsFromQt = discounts.filter((d) => d.fromQuantity === qt);
    if (discountsFromQt.length > 0) {
      finalDiscount = getMaxDiscount(ta, discountsFromQt, segment);
    } else {
      discountsFromQt.push(
        discounts.reduce((d1, d2) => {
          return Math.abs(d2.fromQuantity - qt) < Math.abs(d1.fromQuantity - qt) ? d2 : d1;
        })
      );
      finalDiscount = discountsFromQt.length > 0 ? discountsFromQt[0] : undefined;
    }
    return finalDiscount;
  };

  const getMaxDiscount = function (ta: TradeAgreementDto, ds: DiscountDto[], segment: string | null) {
    const prices: { index: number; price: number }[] = [];
    ds.forEach((discount, index) => {
      const pr = getMaxDiscountNetPrice(ta, segment, discount);
      prices.push({ index: index, price: pr });
    });
    prices.sort((a, b) => a.price - b.price);
    const minPrice = prices[0];
    return ds[minPrice.index];
  };

  const getMaxDiscountNetPrice = function (ta: TradeAgreementDto, segment: string | null, ds?: DiscountDto) {
    let price = getPrice(ta, segment);

    // **** //
    // PAY ATTENTION THIS IS THE BEST SHIT OF THE CENTURY FOR PRODUCTION ARTICLE
    price = price ? price : 100;
    // **** //

    const maxItemDiscounts = config.company.maxItemDiscounts;
    if (ds && maxItemDiscounts > 0) {
      for (let i = 1; i <= maxItemDiscounts; i++) {
        type ObjectKey = keyof typeof ds;

        const key = `discount${i}` as ObjectKey;
        if (ds[key] && typeof ds[key] === 'number') {
          const dValue = ds[key] as number;
          price = price * (1 - dValue / 100);
        }
      }
    }
    return price;
  };

  const getPrice = function (ta: TradeAgreementDto, segment: string | null) {
    if (ta.customerType === CustomerType.ALL) {
      if (ta.salesPrice === 0 && ta.industrialPrice === 0) {
        return ta.price;
      }
      if (segment && segment.length > 0 && segment === SegmentType.INDUSTRIAL) {
        return ta.industrialPrice;
      }
      return ta.salesPrice;
    }
    return ta.price;
  };

  return {
    getPositionDiscounts,
    getBaseTradeAgreements,
    filterBySegment,
    getFinalDiscount,
    getMaxDiscount,
    getMaxDiscountNetPrice,
    getPrice,
  };
}
