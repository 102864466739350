<template>
  <div>
    <IcLoading v-if="isLoading" />
    <div v-else-if="downloads.length > 0">
      <div v-for="(download, index) in downloads" :key="index" class="flex items-center gap-4 p-4">
        <div class="flex w-full">
          <span class="font-light italic"> {{ download.title }}&nbsp;({{ download.fileName }}) </span>
        </div>
        <div class="flex gap-4">
          <a
            :href="download.fileUrl"
            @click.prevent="openFile(download.fileUrl, true)"
            target="_blank"
            class="flex items-center gap-1 text-blue-500 hover:text-blue-700"
          >
            <IcDownload /><span class="hidden">Download</span>
          </a>
          <a
            :href="download.fileUrl"
            @click.prevent="openFile(download.fileUrl, false)"
            target="_blank"
            class="flex items-center gap-1 text-green-500 hover:text-green-700"
          >
            <IcShow /><span class="hidden">View</span>
          </a>
        </div>
      </div>
    </div>
    <div v-else-if="downloads.length === 0" class="flex items-center">
      <IcAlert class="text-infoBlue" />
      <span class="font-light italic">{{ t('articles.attachmentError') }}</span>
    </div>
  </div>
</template>
<script setup lang="ts">
  import IcAlert from '@/components/icons/IcAlert.vue';
  import IcDownload from '@/components/icons/IcDownload.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import IcShow from '@/components/icons/IcShow.vue';
  import type { DownloadDto } from '@/domain/DownloadDto';
  import type { ShoppingCartArticleDto } from '@/domain/masterData/ShoppingCartArticleDto';
  import { useArticleFactory } from '@/modules/articles/composables/useArticleFactory';
  import { useDownloadStore } from '@/modules/downloads/stores/useDownloadStore';
  import { i18n } from '@/plugins/i18n';
  import { DownloadArticleFilters } from '@/util/filters/DownloadArticleFilters';
  import { onMounted, ref } from 'vue';

  interface Props {
    article: ShoppingCartArticleDto;
  }
  const props = defineProps<Props>();

  const { getArticleDownloads } = useArticleFactory();

  const { locale, t } = i18n.global;

  const store = useDownloadStore();
  const { openFile } = store;

  const downloads = ref<Array<DownloadDto>>([]);
  const isLoading = ref<boolean>(true);

  onMounted(async () => {
    const filter = new DownloadArticleFilters(locale.value, props.article.id, props.article.articleGroup?.id);

    getArticleDownloads(filter).then((data) => {
      downloads.value = data;
      isLoading.value = false;
    });
  });
</script>
