<template>
  <div v-if="customerIds.length > 0">
    <!-- SUBHEADER SEARCH AREA -->
    <TheSubheader>
      <ByCustomerFilter
        :customerIds="customerIds"
        v-model:customerId="selectedCustomerId"
        v-model:agentId="agentId"
        v-model:filter="selectedFilter"
      />
    </TheSubheader>

    <div class="mb-32 mt-12 md:mx-[80px] max-md:mx-16">
      <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
        <IcLoading />
      </div>
      <div>
        <!-- TABLE CONTINGENTS BY CUSTOMER -->
        <div class="overflow-x-auto">
          <ByCustomerTable :contingents="selectedCustomerContingentGroup" />
        </div>
      </div>
    </div>
  </div>
  <div v-else class="mb-32 mt-32 md:mx-[80px] max-md:mx-16">
    <AgAlert variant="info">{{ t('contingents.notFound') }}</AgAlert>
  </div>
</template>

<script setup lang="ts">
  import TheSubheader from '@/components/framework/the-subheader/TheSubheader.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import ByCustomerTable from '@/modules/contingents/components/by-customer/ByCustomerTable.vue';
  import ByCustomerFilter from '@/modules/contingents/components/filters/ByCustomerFilter.vue';

  import type { ContingentDto } from '@/domain/masterData/ContingentDto';
  import type { UserHierarchyDto } from '@/domain/UserHierarchyDto';

  import { useMasterData } from '@/composables/data/useMasterData';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { useHierarchy } from '@/composables/useHierarchy';
  import { i18n } from '@/plugins/i18n';
  import { onMounted, ref, watch } from 'vue';

  const { t } = i18n.global;

  const header = useTheHeader();
  header.enabled(true);
  header.visible(true);
  header.title('contingents.contingents', 'contingents.byCustomer');

  const { tables } = useMasterData();
  const { getAllHierarchy } = useHierarchy();

  const isLoading = ref<boolean>(true);

  const customerIdContingentGroup = ref<Map<number, Array<ContingentDto>>>(new Map());
  const selectedCustomerContingentGroup = ref<Map<number, Array<ContingentDto>>>(new Map());

  const customerIds = ref<Array<number>>([]);
  const selectedCustomerId = ref<number>(-1);
  const selectedFilter = ref<string>('');
  const agentId = ref<number>();

  const loadContingents = async function () {
    const contingentsByCustomerId = await tables.contingents.filter((c) => c.customerId !== undefined).toArray();

    contingentsByCustomerId.forEach((c) => {
      if (c.customerId) {
        if (customerIdContingentGroup.value.has(c.customerId)) {
          if (customerIdContingentGroup.value.get(c.customerId)?.some((cc) => cc.id === c.id)) {
            return;
          }
          customerIdContingentGroup.value.get(c.customerId)?.push(c);
        } else {
          customerIdContingentGroup.value.set(c.customerId, [c]);
        }
      }
    });
  };

  const loadContingentsBySelectedCustomer = function (customerId: number) {
    if (customerId === -1) {
      selectedCustomerContingentGroup.value.clear();
      for (const [key, value] of customerIdContingentGroup.value) {
        selectedCustomerContingentGroup.value.set(key, value);
      }
    } else if (customerId !== -1) {
      const selectedCustomerIdContingents = customerIdContingentGroup.value.get(customerId);
      if (selectedCustomerIdContingents && selectedCustomerIdContingents.length > 0) {
        selectedCustomerContingentGroup.value.clear();
        selectedCustomerContingentGroup.value.set(customerId, selectedCustomerIdContingents);
      }
    }
  };

  const loadContingentsBySelectedFilter = function (filter: string) {
    if (filter === 'All') {
      selectedCustomerContingentGroup.value.clear();
      for (const [key, value] of customerIdContingentGroup.value) {
        selectedCustomerContingentGroup.value.set(key, value);
      }
    } else {
      selectedCustomerContingentGroup.value.clear();
      for (const [key, value] of customerIdContingentGroup.value) {
        const contingents = value.filter((c) => c.filter && c.filter === filter);
        if (contingents.length > 0) {
          selectedCustomerContingentGroup.value.set(key, contingents);
        }
      }
    }
  };

  const loadContingentsByAgent = function (agentId: number) {
    if (agentId === -1) {
      selectedCustomerContingentGroup.value.clear();
      for (const [key, value] of customerIdContingentGroup.value) {
        selectedCustomerContingentGroup.value.set(key, value);
      }
    } else {
      selectedCustomerContingentGroup.value.clear();
      for (const [key, value] of customerIdContingentGroup.value) {
        const contingents = value.filter((c) => c.agentId === agentId);
        if (contingents.length > 0) {
          selectedCustomerContingentGroup.value.set(key, contingents);
        }
      }
    }
  };

  watch(selectedCustomerId, (value) => loadContingentsBySelectedCustomer(value));
  watch(selectedFilter, (value) => loadContingentsBySelectedFilter(value));

  watch(agentId, (value) => {
    if (value) {
      loadContingentsByAgent(value);
    }
  });

  onMounted(async () => {
    const hierarchyList: Array<UserHierarchyDto> = await getAllHierarchy();
    if (hierarchyList.length > 0) {
      agentId.value = hierarchyList[0]?.authUser?.id;
    }

    loadContingents().then(() => {
      loadContingentsByAgent(agentId.value ?? -1);
      customerIds.value = Array.from(customerIdContingentGroup.value.keys());
      isLoading.value = false;
    });
  });
</script>
