<template>
  <div>
    <!-- CONTENT AREA -->
    <div>
      <component :is="currentPage" v-model="errorPosition" />
    </div>

    <!-- WIZARD NAVIGATION AREA -->
    <div>
      <OfferWizardNavigation class="navigationBar" v-model="currentStep" v-if="offer && offer.status != 1" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { CountryDto } from '@/domain/masterData/CountryDto';
  import type { OfferDeliveryMethodDto } from '@/domain/offerData/OfferDeliveryMethodDto';
  import type { OfferDeliveryTermDto } from '@/domain/offerData/OfferDeliveryTermDto';
  import type { OfferPaymentMethodDto } from '@/domain/offerData/OfferPaymentMethodDto';
  import type { OfferPaymentTermDto } from '@/domain/offerData/OfferPaymentTermDto';

  import OfferWizardNavigation from '@/modules/offers/components/offer-wizard/navigation/OfferWizardNavigation.vue';
  import OfferWizardAddress from '@/modules/offers/components/offer-wizard/steps/Address/OfferWizardAddress.vue';

  import { useMasterData } from '@/composables/data/useMasterData';
  import { useTheFooter } from '@/composables/framework/useTheFooter';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { OfferStatus } from '@/domain/enumeration/OfferStatus';
  import { isReviewAddressValid } from '@/modules/offers/components/offer-wizard/steps/Address/AddressValidation';
  import {
    isAddressValid,
    isCustomerValid,
  } from '@/modules/offers/components/offer-wizard/steps/customer/CustomerValidation';
  import OfferWizardCustomer from '@/modules/offers/components/offer-wizard/steps/customer/OfferWizardCustomer.vue';
  import OfferWizardNotes from '@/modules/offers/components/offer-wizard/steps/notes/OfferWizardNotes.vue';
  import {
    isDueDateValid,
    isIbanValid,
    isPaymentDeliveryValid,
  } from '@/modules/offers/components/offer-wizard/steps/offer-data/OfferDataValidation';
  import OfferWizardOfferData from '@/modules/offers/components/offer-wizard/steps/offer-data/OfferWizardOfferData.vue';
  import OfferWizardReview from '@/modules/offers/components/offer-wizard/steps/review/OfferWizardReview.vue';
  import OfferWizardShoppingCart from '@/modules/offers/components/offer-wizard/steps/shopping-cart/OfferWizardShoppingCart.vue';
  import { useOfferWizardStore } from '@/modules/offers/stores/useOfferWizardStore';
  import { storeToRefs } from 'pinia';
  import { computed, onMounted, onUnmounted, ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';

  const router = useRouter();
  const route = useRoute();

  const offerStore = useOfferWizardStore();
  const { offer, validationStates } = storeToRefs(offerStore);

  const { tables } = useMasterData();

  const theHeader = useTheHeader();
  theHeader.enabled(false);
  theHeader.visible(false);
  theHeader.saveVisible(offer.value?.status !== OfferStatus.COMPLETED);

  const theFooter = useTheFooter();
  theFooter.enabled(false);
  theFooter.visible(false);

  const countries = ref<Array<CountryDto>>([]);
  const deliveryTerms = ref<Array<OfferDeliveryTermDto>>([]);
  const deliveryMethods = ref<Array<OfferDeliveryMethodDto>>([]);
  const paymentTerms = ref<Array<OfferPaymentTermDto>>([]);
  const paymentMethods = ref<Array<OfferPaymentMethodDto>>([]);

  const currentPage = computed(() => {
    const step = route.params.step;
    if (step === 'customer') {
      return OfferWizardCustomer as object;
    }
    if (step === 'address') {
      return OfferWizardAddress as object;
    }
    if (step === 'shopping-cart') {
      return OfferWizardShoppingCart as object;
    }
    if (step === 'offer-data') {
      return OfferWizardOfferData as object;
    }
    if (step === 'notes') {
      return OfferWizardNotes as object;
    }
    if (step === 'review') {
      validateAll();
      return OfferWizardReview as object;
    }
    return '';
  });

  const currentStep = computed({
    get: () => {
      const step = route.params.step;
      if (typeof step === 'string') {
        return step;
      }
      return route.params.step[0];
    },
    set: (newValue) => {
      router.push({
        name: 'offer-wizard',
        params: { gid: route.params.gid, step: newValue },
      });
    },
  });

  const errorPosition = computed({
    get: () => {
      return {
        id: 0,
        error: true,
      };
    },
    set: (pos) => {
      validationStates.value[pos.id] = pos.error;
    },
  });

  const loadOffer = async function () {
    if (typeof route.params.gid !== 'string') {
      await router.push('/');
      return;
    }
    await offerStore.loadOfferByGid(route.params.gid);
    if (!offerStore.isLoaded) {
      await router.push('/');
      return;
    }
    if (offer.value && offer.value.status === OfferStatus.COMPLETED) {
      await router.push({
        name: 'offer-wizard',
        params: { gid: offer.value.gid, step: 'review' },
      });
      return;
    }
    validateAll();
  };
  const loadCountries = async function () {
    countries.value = await tables.countries.toArray();
  };

  const validateAll = function () {
    if (!offer.value) {
      return;
    }
    const selectedDeliveryAddress = offer.value.deliveryAddressEqualToBilling
      ? offer.value.invoiceAddress
      : offer.value.deliveryAddress;

    const customerName = offer.value?.customer?.name.split(',')[0];
    const customerSurname = offer.value?.customer?.name.split(',')[1];

    validationStates.value[0] =
      isCustomerValid(
        offer.value?.customer,
        offer.value?.paymentMethod ?? null,
        offer.value?.customer.isPrivate ?? false,
        customerName,
        customerSurname
      ) && isAddressValid(offer.value?.invoiceAddress, countries.value);

    validationStates.value[1] = isReviewAddressValid(selectedDeliveryAddress, countries.value);

    if (offer.value?.items.length == undefined || offer.value?.items.length === 0) {
      validationStates.value[2] = false;
    } else {
      validationStates.value[2] = true;
    }
    if (offer.value?.paymentMethod?.id === 4) {
      validationStates.value[3] = isIbanValid(offer.value?.customer);
    }
    validationStates.value[3] =
      isDueDateValid(offer.value.offerDueDate) &&
      isPaymentDeliveryValid(
        offer.value,
        deliveryTerms.value,
        deliveryMethods.value,
        paymentTerms.value,
        paymentMethods.value
      );
  };

  onMounted(async () => {
    loadCountries();
    deliveryTerms.value = await tables.deliveryTerms.toArray();
    deliveryMethods.value = await tables.deliveryMethods.toArray();
    paymentTerms.value = await tables.paymentTerms.toArray();
    paymentMethods.value = await tables.paymentMethods.toArray();
    loadOffer();
  });
  onUnmounted(() => {
    offerStore.reset();
  });
</script>

<style scoped lang="scss">
  .navigationBar {
    @apply fixed bottom-0 left-0 z-[7] max-lg:w-full lg:w-[calc(100%-20.8125em)];
  }
</style>
