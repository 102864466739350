export class DownloadArticleFilters {
  language: string;
  articleId: number;
  articleGroupId?: number;
  constructor(language = '', articleId: number, articleGroupId?: number) {
    this.language = language;
    this.articleId = articleId;
    if (undefined !== articleGroupId) {
      this.articleGroupId = articleGroupId;
    }
  }
}
