<template>
  <div>
    <div v-if="props.draftOffers.length === 0">
      <div>
        <span class="signal">&nbsp;</span>
        <span class="font-bold">{{ t('customers.createdOffers') }}</span>
      </div>
      <div class="pt-12">{{ t('customers.noDataFound') }}!</div>
    </div>
    <div v-else>
      <div>
        <span class="signal">&nbsp;</span>
        <span class="font-bold">{{ t('customers.createdOffers') }}</span>
      </div>
      <div class="max-h-512 overflow-y-auto py-12 min-w-[533px]">
        <div class="tableHeader">
          <div class="tableHeader">
            <div class="headerColumn w-fit" @click.stop="updateSorting(ColumnName.DATE)">
              <span>{{ t('customers.creationDate') }}</span>
              <div v-if="sorting.col === ColumnName.DATE">
                <span v-if="sorting.order === Order.DESC">
                  <IcChevronDown class="h-12 w-12" />
                </span>
                <span v-else-if="sorting.order === Order.ASC">
                  <IcChevronUp class="h-12 w-12" />
                </span>
                <span v-else class="h-12 w-12">&nbsp;</span>
              </div>
            </div>
            <div class="headerColumn w-fit" @click.stop="updateSorting(ColumnName.AMOUNT)">
              <span>{{ t('customers.offersTotal') }}</span>
              <div v-if="sorting.col === ColumnName.AMOUNT">
                <span v-if="sorting.order === Order.DESC">
                  <IcChevronDown class="h-12 w-12" />
                </span>
                <span v-else-if="sorting.order === Order.ASC">
                  <IcChevronUp class="h-12 w-12" />
                </span>
                <span v-else class="h-12 w-12">&nbsp;</span>
              </div>
            </div>
            <div class="headerColumn right">&nbsp;</div>
          </div>
        </div>
        <div v-for="offer in offers" :key="offer.gid">
          <AgCollapsible show-icon class="border-t border-neutral-500">
            <template #header>
              <div class="table">
                <div class="tableColumn">
                  {{ formatDate(offer.createdAt) }}
                </div>
                <div class="tableColumn">
                  {{ formatNumberToEuro(getNetTotal(offer)) }}
                </div>
                <div class="tableColumn right">
                  <AgButton variant="ghost" @click.stop="onCopy(offer)" :disabled="props.isCustomerBlocked">
                    <template #icon><IcCopy /></template>
                  </AgButton>
                  <AgButton variant="ghost" @click.stop="onEdit(offer)" :disabled="props.isCustomerBlocked">
                    <template #icon><IcEdit /></template>
                  </AgButton>
                  <AgButton variant="ghost" @click.stop="onDelete(offer)">
                    <template #icon><IcDelete /></template>
                  </AgButton>
                </div>
              </div>
            </template>
            <template #default>
              <ArticlesTable :items="offer.items" />
            </template>
          </AgCollapsible>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { OfferDto } from '@/domain/offerData/OfferDto';

  import IcCopy from '@/components/icons/IcCopy.vue';
  import IcDelete from '@/components/icons/IcDelete.vue';
  import IcEdit from '@/components/icons/IcEdit.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgCollapsible from '@/components/library/collapsible/AgCollapsible.vue';
  import ArticlesTable from '@/modules/customers/components/ArticlesTable.vue';

  import IcChevronDown from '@/components/icons/IcChevronDown.vue';
  import IcChevronUp from '@/components/icons/IcChevronUp.vue';
  import { useDateFormatting } from '@/composables/useDateFormatting';
  import { useNumberFormatting } from '@/composables/useNumberFormatting';
  import { useOfferFactory } from '@/modules/offers/composables/useOfferFactory';
  import { useOffers } from '@/modules/offers/composables/useOffers';
  import { i18n } from '@/plugins/i18n';
  import { router } from '@/plugins/router';
  import { Order } from '@/util/Order';
  import { Sorting } from '@/util/Sorting';
  import { cloneDeep } from 'lodash';
  import { ref, watch } from 'vue';

  const { t } = i18n.global;

  const { copyOffer } = useOfferFactory();
  const { deleteOffer, saveOffer } = useOffers();
  const { formatDate } = useDateFormatting();
  const { formatNumberToEuro } = useNumberFormatting();

  interface Props {
    draftOffers: Array<OfferDto>;
    isCustomerBlocked: boolean;
  }

  const props = defineProps<Props>();

  enum ColumnName {
    DATE = 'date',
    AMOUNT = 'amount',
  }

  const offers = ref<Array<OfferDto>>([]);

  const sorting = ref<Sorting>(new Sorting(ColumnName.DATE, Order.DESC));

  const getNetTotal = function (offer: OfferDto) {
    return offer.items.reduce((acc, item) => acc + (item.finalPrice ?? 0), 0);
  };

  const updateSorting = (column: ColumnName) => {
    sorting.value.col = column;
    sorting.value.order = sorting.value.order === Order.ASC ? Order.DESC : Order.ASC;
    if (column === ColumnName.DATE) {
      if (sorting.value.order == Order.ASC) {
        offers.value.sort((a, b) => (a.createdAt ?? 0) - (b.createdAt ?? 0));
      } else {
        offers.value.sort((a, b) => (b.createdAt ?? 0) - (a.createdAt ?? 0));
      }
    } else if (column === ColumnName.AMOUNT) {
      if (sorting.value.order == Order.ASC) {
        offers.value.sort((a, b) => getNetTotal(a) - getNetTotal(b));
      } else {
        offers.value.sort((a, b) => getNetTotal(b) - getNetTotal(a));
      }
    }
  };

  const onEdit = async function (offer: OfferDto) {
    await router.push({
      name: 'offer-wizard',
      params: { gid: offer.gid, step: 'customer' },
    });
  };

  const onCopy = async function (offer: OfferDto) {
    const newOffer = copyOffer(cloneDeep(offer));

    await saveOffer(newOffer).then(
      async () =>
        await router.push({
          name: 'offer-wizard',
          params: { gid: newOffer.gid, step: 'review' },
        })
    );
  };

  const onDelete = async function (offer: OfferDto) {
    await deleteOffer(offer).then(() => offers.value.splice(offers.value.indexOf(offer), 1));
  };

  watch(
    () => props.draftOffers,
    () => {
      offers.value = [...props.draftOffers];
      updateSorting(ColumnName.DATE);
    },
    {
      immediate: true,
    }
  );
</script>

<style scoped lang="scss">
  .tableHeader {
    @apply grid auto-cols-fr grid-flow-col gap-8 py-8 font-light text-neutral-550;
  }

  .headerColumn {
    @apply flex items-center hover:cursor-pointer;
  }

  .table {
    @apply grid auto-cols-fr grid-flow-col gap-8 py-8;
  }

  .tableColumn {
    @apply flex items-center;
  }

  .right {
    @apply justify-end;
  }

  .center {
    @apply justify-center;
  }

  .tooltip {
    @apply invisible absolute -mt-32 rounded bg-neutral-500 p-4;
  }

  .has-tooltip:hover .tooltip {
    @apply visible z-20;
  }

  .signal {
    padding: 0.2em;
    margin: 0 0.7em 0 0;
    background-color: #1f4092;
  }
</style>
