<template>
  <AgModal v-if="internalValue && props.customerNote" fit-width fit-height>
    <template #header>
      <div class="text-2xl font-bold">{{ t('customers.editNote') }}</div>
    </template>
    <template #content>
      <div v-if="noteInModal">
        <div class="flex flex-col gap-16 pt-24 md:min-w-[333px]">
          <AgFormGroup :label="t('customers.noteDate')" required>
            <AgFormInput
              v-model="internalDate"
              :validation-state="noteDateValidationState"
              :error-message="t('customers.dateRequired')"
              type="datetime-local"
            />
          </AgFormGroup>
          <AgFormGroup :label="t('customers.category')" required>
            <AgFormSelect :options="categoryOptions" v-model="internalCategory" />
          </AgFormGroup>
          <AgFormGroup :label="t('customers.subject')" required>
            <AgFormInput
              v-model="noteInModal.subject"
              :validation-state="subjectValidationState"
              :error-message="t('customers.subjectRequired')"
              :max-lenght="50"
            />
          </AgFormGroup>
          <AgFormGroup :label="t('customers.note')" required>
            <AgFormTextarea
              v-model="noteInModal.note"
              :rows="10"
              :validation-state="noteValidationState"
              :error-message="t('customers.noteRequired')"
              :max-lenght="300"
              :resize="false"
            />
          </AgFormGroup>
        </div>
      </div>
    </template>

    <template #footer>
      <!-- BUTTON -->
      <AgButton variant="secondary" @click="closeModal">{{ t('customers.cancel') }}</AgButton>
      <AgButton variant="primary" @click="save" :disabled="disabled">{{ t('customers.apply') }}</AgButton>
    </template>
  </AgModal>
</template>

<script setup lang="ts">
  import type { AgFormSelectOption } from '@/components/library/form-select/AgFormSelectOption';
  import type { CustomerNotesDto } from '@/domain/CustomerNoteDto';
  import type { CustomerNotesRequest } from '@/domain/internal/CustomerNotesRequest';

  import AgButton from '@/components/library/button/AgButton.vue';
  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';
  import AgFormSelect from '@/components/library/form-select/AgFormSelect.vue';
  import AgFormTextarea from '@/components/library/form-textarea/AgFormTextarea.vue';
  import AgModal from '@/components/library/modal/AgModal.vue';

  import { useCustomerNotesStore } from '@/modules/customers/composables/useCustomerNotesStore';
  import { i18n } from '@/plugins/i18n';
  import { cloneDeep } from 'lodash';
  import { computed, ref, watch } from 'vue';

  const { t } = i18n.global;

  const store = useCustomerNotesStore();
  const { editCustomerNote } = store;

  interface Props {
    modelValue?: boolean;
    customerNote: CustomerNotesDto;
  }
  const props = withDefaults(defineProps<Props>(), {
    modelValue: false,
  });

  const categoryOptions: Array<AgFormSelectOption> = [
    { label: 'customers.visit', key: 'customers.visit' },
    { label: 'customers.contact', key: 'customers.contact' },
    { label: 'customers.general', key: 'customers.general' },
  ];

  const emit = defineEmits(['update:modelValue']);

  const noteDateValidationState = ref<boolean>(false);
  const categoryValidationState = ref<boolean>(false);
  const subjectValidationState = ref<boolean>(false);
  const noteValidationState = ref<boolean>(false);

  const noteInModal = ref<CustomerNotesDto>();

  const internalValue = computed({
    get: () => props.modelValue,
    set: (newValue) => emit('update:modelValue', newValue),
  });

  const internalDate = computed({
    get: () => {
      if (noteInModal.value && noteInModal.value.dateTimestamp) {
        const timestamp = noteInModal.value.dateTimestamp * 1000;
        const date = new Date(timestamp);
        return date.toISOString().slice(0, 16);
      }
      return new Date().toISOString().slice(0, 16);
    },
    set: (newValue) => {
      if (newValue && noteInModal.value) {
        const timestamp = new Date(newValue).getTime();
        noteInModal.value.dateTimestamp = getTimestamp(timestamp);
      } else if (noteInModal.value) {
        noteInModal.value.dateTimestamp = 0;
      }
    },
  });

  const internalCategory = computed({
    get: () => {
      const category = noteInModal.value ? noteInModal.value.category : '';
      if (category) {
        if ('Besuch Visita Visit'.includes(category)) {
          return 'customers.visit';
        } else if ('Anruf/E-Mail Call/Email Telefonata/Email'.includes(category)) {
          return 'customers.contact';
        } else if ('Allgemein General Generale'.includes(category)) {
          return 'customers.general';
        }
      }
      return 'customers.visit';
    },
    set: (newCategory) => {
      if (noteInModal.value) {
        noteInModal.value.category = newCategory;
      }
    },
  });

  const disabled = computed(() => {
    if (!noteInModal.value) {
      return true;
    }
    if (
      !noteDateValidationState.value ||
      !categoryValidationState.value ||
      !noteValidationState.value ||
      !subjectValidationState.value
    ) {
      return true;
    }
    if (
      props.customerNote.dateTimestamp === noteInModal.value.dateTimestamp &&
      props.customerNote.category === noteInModal.value.category &&
      props.customerNote.subject === noteInModal.value.subject &&
      props.customerNote.note === noteInModal.value.note
    ) {
      return true;
    }
    return false;
  });

  const closeModal = function () {
    internalValue.value = false;
  };

  function getTimestamp(date: number) {
    return Math.floor(date / 1000);
  }

  const save = async function () {
    if (!noteInModal.value) {
      return;
    }

    const request: CustomerNotesRequest = {
      customerId: props.customerNote.customerId,
      date: noteInModal.value.dateTimestamp.toString(),
      category: t(noteInModal.value.category),
      subject: noteInModal.value.subject,
      note: noteInModal.value.note,
    };
    await editCustomerNote(noteInModal.value.id, request);

    closeModal();
  };

  watch(
    noteInModal,
    (value) => {
      if (value) {
        if (value.subject.length < 3) {
          subjectValidationState.value = false;
        } else {
          subjectValidationState.value = true;
        }
        if (value.note.length < 3) {
          noteValidationState.value = false;
        } else {
          noteValidationState.value = true;
        }
        if (value.category) {
          categoryValidationState.value = true;
        } else {
          categoryValidationState.value = false;
        }
        if (value.dateTimestamp) {
          noteDateValidationState.value = true;
        } else {
          noteDateValidationState.value = false;
        }
      }
    },
    { immediate: false, deep: true }
  );

  watch(
    internalValue,
    (value) => {
      if (value) {
        noteInModal.value = cloneDeep(props.customerNote);
      }
    },
    { immediate: true }
  );
</script>
