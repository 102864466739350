import type { DimensionItemDto } from '@/domain/DimensionItemDto';
import type { ArticleDto } from '@/domain/masterData/ArticleDto';
import type { ShoppingCartArticleDto } from '@/domain/masterData/ShoppingCartArticleDto';

import { useApi } from '@/composables/api/useApi';
import { useMasterData } from '@/composables/data/useMasterData';
import { useCommon } from '@/composables/useCommon';
import { useTranslatedText } from '@/composables/useTransalteText';
import type { ContainerDto } from '@/domain/data/ContainerDto';
import type { DownloadDto } from '@/domain/DownloadDto';
import type { StockAvailabilityDto } from '@/domain/StockAvailabilityDto';
import type { DownloadArticleFilters } from '@/util/filters/DownloadArticleFilters';
import { Optional } from '@/util/Optional';
import { useError } from '@/util/useError';

export function useArticleFactory() {
  const masterData = useMasterData();
  const { handleCatchLocalError, handleCatchServerError } = useError();
  const api = useApi();

  const { getTranslatedText } = useTranslatedText();
  const { getExternalCode, getArticleGroup, getArticleType } = useCommon();

  const getShoppingCartArticles = async function () {
    const articles = await masterData.tables.articles.toArray();
    articles.sort((a1, a2) => getTranslatedText(a1.title).localeCompare(getTranslatedText(a2.title)));

    const shoppingCartArticles = await Promise.all(
      articles.map(async (article) => await articleToShoppingCartArticle(article))
    );
    return Optional.of(shoppingCartArticles);
  };

  const articleToShoppingCartArticle = async function (
    article: ArticleDto,
    customerId?: number | null
  ): Promise<ShoppingCartArticleDto> {
    return {
      id: article.id,
      code: article.code,

      externalCode: await getExternalCode(article.id, customerId),

      type: article.type,
      unit: article.unit,
      searchName: article.searchName,
      sizeForNumber: article.sizeForNumber,
      sizeForWeight: article.sizeForWeight,
      contingentGroupId: article.contingentGroupId,
      articleTaxCode: article.articleTaxCode,
      minPrice: article.minPrice,
      title: article.title,
      infoTitle: article.infoTitle,
      attachmentsNumber: article.attachmentsNumber,

      articleGroup: await getArticleGroup(article.articleGroupId),
      articleType: await getArticleType(article.articleTypeId),

      packagings: article.packagings
        ? article.packagings.sort((pa, pb) =>
            getTranslatedText(pa.unit?.title).localeCompare(getTranslatedText(pb.unit?.title))
          )
        : [],

      articleDimensionValueConfigIds: article.articleDimensionValueConfigIds,
      defaultDimensionValueConfigIds: article.defaultDimensionValueConfigIds,
      priceGroupIds: article.priceGroupIds,
    };
  };

  const getDimOptions = async function (dimensionIds: Array<number>, defaultDimensions: Array<number> = []) {
    if (dimensionIds.length === 0) {
      return null;
    }
    const dimensionValueConfigs = await masterData.tables.articleDimensionValueConfigs.toArray();
    const dimOptions: { [key: string]: Array<DimensionItemDto> } = {};

    dimensionValueConfigs.forEach((item) => {
      if (dimensionIds.includes(item.id)) {
        const key = item.dimensionConfig.id.toString();
        if (dimOptions[key]) {
          dimOptions[key].push({
            id: item.dimensionConfig.id,
            code: item.dimensionConfig.code,
            title: item.dimensionConfig.title,
            value: {
              id: item.id,
              code: item.code,
              title: item.title,
              default: defaultDimensions.includes(item.id),
            },
          });
        } else {
          dimOptions[key] = [
            {
              id: item.dimensionConfig.id,
              code: item.dimensionConfig.code,
              title: item.dimensionConfig.title,
              value: {
                id: item.id,
                code: item.code,
                title: item.title,
                default: defaultDimensions.includes(item.id),
              },
            },
          ];
        }
      }
    });
    return dimOptions;
  };

  const getArticleDownloads = async function (filters: DownloadArticleFilters) {
    try {
      let articleDownloads: Array<DownloadDto> = [];
      const params = {
        'filters.language': filters.language,
        'filters.articleId': `${filters.articleId}`,
        ...(filters.articleGroupId ? { 'filters.articleGroupId': `${filters.articleGroupId}` } : ''),
      };
      const searchParams = new URLSearchParams(params);
      const requestUri = `/downloads/article?${searchParams}`;
      const container = await api.fetch<ContainerDto>(requestUri);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return [];
      }
      if (container.data.downloads) {
        articleDownloads = container.data.downloads;
      }
      return articleDownloads;
    } catch (e) {
      handleCatchLocalError(e);
      return [];
    }
  };

  // /stock/article/{articleCode}?app_version=2023.1.4512813617
  const getArticleStock = async function (articleCode?: string | null): Promise<Array<StockAvailabilityDto> | null> {
    const stockAvailability: Array<StockAvailabilityDto> = [];
    try {
      if (!articleCode) {
        return stockAvailability;
      }
      const params = {
        app_version: '2023.1.4512813617',
      };
      const searchParams = new URLSearchParams(params);
      const requestUri = `/stock/article/${articleCode}?${searchParams}`;

      const container = await api.fetch<ContainerDto>(requestUri);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return null;
      }
      if (container.data.articleStock) {
        stockAvailability.push(...container.data.articleStock);
      }
    } catch (error) {
      handleCatchLocalError(error);
      return null;
    }
    return stockAvailability;
  };

  return {
    getShoppingCartArticles,
    getDimOptions,
    getArticleDownloads,
    getArticleStock,
  };
}
