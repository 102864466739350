import { useMasterData } from '@/composables/data/useMasterData';
import { useHierarchy } from '@/composables/useHierarchy';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useContingentsStore = defineStore('contingents-store', () => {
  const byArticleGroupSize = ref<number>(0);
  const byCustomerSize = ref<number>(0);
  const totalSize = ref<number>(0);

  const { tables } = useMasterData();
  const { getAllHierarchy } = useHierarchy();

  async function refreshContingentsData() {
    totalSize.value = await getTotalSize();
    byArticleGroupSize.value = await getByArticleSize();
    byCustomerSize.value = await getByCustomer();
  }

  const getTotalSize = async function () {
    const hierarchy = await getAllHierarchy();
    const agentId = hierarchy[0]?.authUser.id;

    if (!agentId) {
      return 0;
    }
    const contingents = await tables.contingents.filter((c) => c.agentId === agentId).toArray();
    return contingents.length;
  };

  const getByArticleSize = async function () {
    const hierarchy = await getAllHierarchy();
    const agentId = hierarchy[0]?.authUser.id;

    if (!agentId) {
      return 0;
    }
    const contingentsByArticle = await tables.contingents
      .orderBy('[agentId+contingentGroupArticleId+articleDimensionValueConfigId]')
      .filter((c) => c.agentId === agentId)
      .uniqueKeys();

    return contingentsByArticle.length;
  };

  const getByCustomer = async function () {
    const hierarchy = await getAllHierarchy();
    const agentId = hierarchy[0]?.authUser.id;

    if (!agentId) {
      return 0;
    }
    const contingentsByCustomer = await tables.contingents
      .where('agentId')
      .equals(agentId)
      .filter((c) => c.customerId !== null)
      .toArray();

    const uniqueCustomerIds = new Set(contingentsByCustomer.map((contingent) => contingent.customerId));

    return uniqueCustomerIds.size;
  };

  return {
    refreshContingentsData,
    totalSize,
    byArticleGroupSize,
    byCustomerSize,
  };
});
