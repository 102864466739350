<template>
  <AgBox>
    <router-link to="/contingents/by-article-group" class="router-link">
      <span>{{ t('contingentArticlesDashboard') }}:</span>
      <span v-if="byArticleGroupSize && byArticleGroupSize > 0">
        {{ byArticleGroupSize }}
      </span>
      <span v-else>-</span>
    </router-link>
    <router-link to="/contingents/by-customer" class="router-link">
      <span>{{ t('contingentCustomersDashboard') }}:</span>
      <span v-if="byCustomerSize && byCustomerSize > 0">
        {{ byCustomerSize }}
      </span>
      <span v-else>-</span>
    </router-link>
    <div class="flex items-center justify-between">
      <span>{{ t('contingentTotalDashboard') }}:</span>
      <span v-if="totalSize && totalSize > 0">
        {{ totalSize }}
      </span>
      <span v-else>-</span>
    </div>
  </AgBox>
</template>

<script setup lang="ts">
  import AgBox from '@/components/library/box/AgBox.vue';
  import { useMasterData } from '@/composables/data/useMasterData';
  import { useContingentsStore } from '@/modules/contingents/stores/useContingentsStore';

  import { i18n } from '@/plugins/i18n';
  import { storeToRefs } from 'pinia';
  import { watch } from 'vue';

  const { t } = i18n.global;
  const contingentsStore = useContingentsStore();
  const { totalSize, byArticleGroupSize, byCustomerSize } = storeToRefs(contingentsStore);
  const { refreshContingentsData } = contingentsStore;
  const { isFullReloading, isLoading } = useMasterData();

  watch(
    [isFullReloading, isLoading],
    async ([fullReload, loading]) => {
      if (!fullReload && !loading) {
        await refreshContingentsData();
      }
    },
    { immediate: true }
  );
</script>

<style lang="scss" scoped>
  .router-link {
    @apply flex items-center justify-between pb-8 text-white;
  }

  .router-link:hover {
    @apply underline;
  }
</style>
