<template>
  <div>
    <div class="mb-8 flex flex-row items-center justify-between">
      <router-link to="/contingents/by-article-group" class="router-link" :title="t('contingents.goToArticleGroup')">
        {{ t('contingentEntryDashboard') }}
      </router-link>
    </div>
    <DashboardWidgetContingetTable />
  </div>
</template>
<script setup lang="ts">
  import DashboardWidgetContingetTable from '@/modules/contingents/components/dashboard-widget/table/DashboardWidgetContingetTable.vue';
  import { i18n } from '@/plugins/i18n';

  const { t } = i18n.global;
</script>

<style lang="scss" scoped>
  .router-link {
    @apply text-xl font-bold text-white;
  }
</style>
