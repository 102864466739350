export class CustomerNotesFilter {
  search: string;
  category: string;
  date: string;

  typeDateFilter?: number;
  constructor(search: string = '', category: string = '', date: string = '', typeDateFilter: number = 0) {
    this.search = search;
    this.category = category;
    this.date = date;
    this.typeDateFilter = typeDateFilter;
  }
}
