<template>
  <div>
    <!-- CONTENT AREA -->
    <div>
      <component :is="currentPage" v-model="errorPosition" />
    </div>

    <!-- WIZARD NAVIGATION AREA -->
    <div>
      <OrderWizardNavigation class="navigationBar" v-model="currentStep" v-if="order && order.status != 1" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { CountryDto } from '@/domain/masterData/CountryDto';
  import type { OrderDeliveryMethodDto } from '@/domain/orderData/OrderDeliveryMethodDto';
  import type { OrderDeliveryTermDto } from '@/domain/orderData/OrderDeliveryTermDto';
  import type { OrderPaymentMethodDto } from '@/domain/orderData/OrderPaymentMethodDto';
  import type { OrderPaymentTermDto } from '@/domain/orderData/OrderPaymentTermDto';

  import OrderWizardNavigation from '@/modules/orders/components/order-wizard/navigation/OrderWizardNavigation.vue';
  import OrderWizardAddress from '@/modules/orders/components/order-wizard/steps/address/OrderWizardAddress.vue';
  import OrderWizardCustomer from '@/modules/orders/components/order-wizard/steps/customer/OrderWizardCustomer.vue';
  import OrderWizardNotes from '@/modules/orders/components/order-wizard/steps/notes/OrderWizardNotes.vue';
  import OrderWizardOrderData from '@/modules/orders/components/order-wizard/steps/order-data/OrderWizardOrderData.vue';
  import OrderWizardReview from '@/modules/orders/components/order-wizard/steps/review/OrderWizardReview.vue';
  import OrderWizardShoppingCart from '@/modules/orders/components/order-wizard/steps/shopping-cart/OrderWizardShoppingCart.vue';

  import { useMasterData } from '@/composables/data/useMasterData';
  import { useTheFooter } from '@/composables/framework/useTheFooter';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { OrderStatus } from '@/domain/enumeration/OrderStatus';
  import { isReviewAddressValid } from '@/modules/orders/components/order-wizard/steps/address/AddressValidation';
  import {
    isAddressValid,
    isCustomerValid,
  } from '@/modules/orders/components/order-wizard/steps/customer/CustomerValidation';
  import {
    isIbanValid,
    isPaymentDeliveryValid,
  } from '@/modules/orders/components/order-wizard/steps/order-data/OrderDataValidation';
  import { useOrderWizardStore } from '@/modules/orders/stores/useOrderWizardStore';
  import { storeToRefs } from 'pinia';
  import { computed, onMounted, onUnmounted, ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';

  const router = useRouter();
  const route = useRoute();

  const orderStore = useOrderWizardStore();

  const { tables } = useMasterData();
  const { order, validationStates } = storeToRefs(orderStore);

  const theHeader = useTheHeader();
  theHeader.enabled(false);
  theHeader.visible(false);
  theHeader.saveVisible(order.value?.status !== OrderStatus.COMPLETED);

  const theFooter = useTheFooter();
  theFooter.enabled(false);
  theFooter.visible(false);

  const countries = ref<Array<CountryDto>>([]);
  const deliveryTerms = ref<Array<OrderDeliveryTermDto>>([]);
  const deliveryMethods = ref<Array<OrderDeliveryMethodDto>>([]);
  const paymentTerms = ref<Array<OrderPaymentTermDto>>([]);
  const paymentMethods = ref<Array<OrderPaymentMethodDto>>([]);

  const currentPage = computed(() => {
    const step = route.params.step;
    if (step === 'customer') {
      return OrderWizardCustomer as object;
    }
    if (step === 'address') {
      return OrderWizardAddress as object;
    }
    if (step === 'shopping-cart') {
      return OrderWizardShoppingCart as object;
    }
    if (step === 'order-data') {
      return OrderWizardOrderData as object;
    }
    if (step === 'notes') {
      return OrderWizardNotes as object;
    }
    if (step === 'review') {
      validateAll();
      return OrderWizardReview as object;
    }
    return '';
  });

  const currentStep = computed({
    get: () => {
      const step = route.params.step;
      if (typeof step === 'string') {
        return step;
      }
      return route.params.step[0];
    },
    set: (newValue) => {
      router.push({
        name: 'order-wizard',
        params: { gid: route.params.gid, step: newValue },
      });
    },
  });

  const errorPosition = computed({
    get: () => {
      return {
        id: 0,
        error: true,
      };
    },
    set: (pos) => {
      validationStates.value[pos.id] = pos.error;
    },
  });

  const loadOrder = async function () {
    if (typeof route.params.gid !== 'string') {
      await router.push('/');
      return;
    }
    await orderStore.loadOrderByGid(route.params.gid);
    if (!orderStore.isLoaded) {
      await router.push('/');
      return;
    }
    if (order.value && order.value.status === OrderStatus.COMPLETED) {
      await router.push({
        name: 'order-wizard',
        params: { gid: order.value.gid, step: 'review' },
      });
      return;
    }
    validateAll();
  };

  const loadCountries = async function () {
    countries.value = await tables.countries.toArray();
  };

  const validateAll = function () {
    const selectedDeliveryAddress = order.value?.deliveryAddressEqualToBilling
      ? order.value?.invoiceAddress
      : order.value?.deliveryAddress;
    if (!order.value) {
      return;
    }
    const customerName = order.value.customer?.name.split(',')[0];
    const customerSurame = order.value.customer?.name.split(',')[1];
    validationStates.value[0] =
      isCustomerValid(
        order.value?.customer,
        order.value?.paymentMethod ?? null,
        order.value?.customer.isPrivate ?? false,
        customerName,
        customerSurame
      ) && isAddressValid(order.value?.invoiceAddress, countries.value);

    validationStates.value[1] = isReviewAddressValid(selectedDeliveryAddress, countries.value);

    if (order.value?.items.length == undefined || order.value?.items.length === 0) {
      validationStates.value[2] = false;
    } else {
      validationStates.value[2] = true;
    }
    if (order.value?.paymentMethod?.id === 4) {
      validationStates.value[3] =
        isIbanValid(order.value?.customer) &&
        isPaymentDeliveryValid(
          order.value,
          deliveryTerms.value,
          deliveryMethods.value,
          paymentTerms.value,
          paymentMethods.value
        );
    } else {
      validationStates.value[3] = isPaymentDeliveryValid(
        order.value,
        deliveryTerms.value,
        deliveryMethods.value,
        paymentTerms.value,
        paymentMethods.value
      );
    }
  };

  onMounted(async () => {
    loadCountries();
    deliveryTerms.value = await tables.deliveryTerms.toArray();
    deliveryMethods.value = await tables.deliveryMethods.toArray();
    paymentTerms.value = await tables.paymentTerms.toArray();
    paymentMethods.value = await tables.paymentMethods.toArray();
    loadOrder();
  });

  onUnmounted(() => {
    orderStore.reset();
  });
</script>

<style scoped lang="scss">
  .navigationBar {
    @apply fixed bottom-0 left-0 z-[7] max-lg:w-full lg:w-[calc(100%-20.8125em)];
  }
</style>
